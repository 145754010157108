import React, { ReactNode, useEffect, useState } from "react";
import logo from "../assets/logo.png";
import sfondo from "../assets/sfondo.jpg";
import api from "../api/api";
import Stepper from "../components/Stepper";
import Card from "../components/Card";
import {
  convertDateToInput,
  convertDateToUser,
} from "../utils/convertDateToInput";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "../interfaces/User";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CartCard from "../components/CartCard";
import CheckoutForm from "../components/CheckoutForm";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "moment/locale/it";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { Appointment } from "../interfaces/Appointment";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import Header from "../components/Header";

const initialOptions = {
  clientId:
    "AT2W6Nswav1iGT4raqIMcEUiDH5AFf-Rv00_FgDcLMMxZV6B0jMRGULGAr-fRpv_CusFuDY_PSwDolkL",
  "enable-funding": "venmo",
  "disable-funding": "paylater,card",
  "data-sdk-integration-source": "integrationbuilder_sc",
  currency: "EUR",
};

const nextDay: Date = new Date();
nextDay.setDate(nextDay.getDate() + 1);

export default function BookCustom() {
  const [categories, setCategories] = useState<any[]>([]); //categorie
  const [filteredCategories, setFilteredCategories] = useState<any[]>([]); //categorie filtrate
  const [services, setServices] = useState<any[]>([]); //servizi
  const [filteredServices, setFilteredServices] = useState<any[]>([]); //servizi filtrate
  const [providers, setProviders] = useState<any[]>([]); //providers
  const [filteredProviders, setFilteredProviders] = useState<any[]>([]); //providers filtrate
  const [filter, setFilter] = useState<string>("");
  const [avaiableSlots, setAvaiableSlots] = useState<any[]>([]); //slots
  const [servicesForCategory, setServicesForCategory] = useState<any[]>([]); //servizi una volta selezionata la categoria
  const [providersForService, setProvidersForService] = useState<any[]>([]); //providers una volta selezionato il servizio
  const [selectedService, setSelectedService] = useState<any>(null); //salvo il servizio selezionato
  const [selectedCategory, setSelectedCategory] = useState<any>(null); //Salvo la categoria selezionata
  const [selectedProvider, setSelectedProvider] = useState<any>(null); //Salvo il provider selezionato
  const [selectedDate, setSelectedDate] = useState<Date>(nextDay);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [operation, setOperation] = useState<string>("Registration");
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [workingDate, setWorkingDate] = useState<any[]>([]);
  const [loadingCalendar, setLoadingCalendar] = useState<boolean>(false);
  const [loadingSlots, setLoadingSlots] = useState<boolean>(false);
  const [confirmBook, setConfirmBook] = useState<boolean>(false);
  const [cart, setCart] = useState<Appointment[]>([]); //carrello con tutti gli appointment
  const [appointment, SetAppointment] = useState<any>([]);
  const [description, SetDescription] = useState<string>("");
  const [message, setMessage] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeApplied, setPromoCodeApplied] = useState<boolean>(false);
  const steps = [{ name: "Categoria" }, { name: "Servizio" }];

  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    fetch("https://api.mamamind.it/api/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const filterData = (stringToSearch: string) => {
    switch (step) {
      case 0:
        let arrCat = [...categories];
        setFilteredCategories(
          arrCat.filter((el) =>
            el.name.toLowerCase().includes(stringToSearch.toLowerCase())
          )
        );
        break;
      case 1:
        let arrServi = [...servicesForCategory];
        setFilteredServices(
          arrServi.filter((el) =>
            el.name.toLowerCase().includes(stringToSearch.toLowerCase())
          )
        );
        break;
      case 2:
        let arrProv = [...providersForService];
        setFilteredProviders(
          arrProv.filter((el) =>
            el.name.toLowerCase().includes(stringToSearch.toLowerCase())
          )
        );
        break;
    }

    let arr = [...categories];
    setFilteredCategories(
      arr.filter((el) =>
        el.name.toLowerCase().includes(stringToSearch.toLowerCase())
      )
    );
  };

  const cleanData = () => {
    setFilteredCategories(servicesForCategory);
    setFilteredProviders(providersForService);
    setFilteredServices(services);
  };

  const loadData = async () => {
    setLoading(true);
    const result = await api.post("/SB/JSONRP", {
      url: "https://user-api.simplybook.me/",
      method: "getCategoriesList",
      params: null,
    });
    let valuesArray: any[] = Object.values(result.data);
    valuesArray = valuesArray.filter((el) => el.id == 5 || el.id == 6);

    setCategories(valuesArray);
    setFilteredCategories(valuesArray);

    const res_services = await api.post("/SB/REST", {
      url: "/admin/services",
      method: "get",
    });
    setServices(
      res_services.data.data //.filter((el: { id: number }) => el.id !== 15)
    );

    const res_providers = await api.post("/SB/REST", {
      url: "/admin/providers",
      method: "get",
    });
    setProviders(
      res_providers.data.data.filter(
        (el: { is_visible: boolean }) => el.is_visible
      )
    );

    if (location.state) {
      // se arrivo dalla survey
      setStep(4);
      let { time, date, provider } = location.state;
      setSelectedTime(time);
      setSelectedDate(date);
      setSelectedProvider(provider);
      let service = res_services.data.data.filter(
        (el: { id: any }) => el.id == location.state.servizio
      )[0];
      setSelectedService(service);

      handleBlockSlotFromSurvey(
        provider.id,
        service.id,
        date,
        time,
        service.name,
        provider.name,
        provider.picture_preview,
        service.price
      );

      // {selectedService.name}           </p>
      //<p>Data: {convertDateToUser(selectedDate)}</p>
      //<p>Orario: {selectedTime.slice(0, -3)}</p>
    }
    setLoading(false);
  };

  const handleCategorySelection = (ids: number[], cat_sel: any) => {
    setStep(step + 1);

    //services.map((el) => console.log(ids.indexOf(el.id.toString()) !== -1));

    let selected_services = services.filter(
      (el) => ids.indexOf(el.id.toString()) !== -1
    );
    setFilter("");
    setFilteredCategories(categories);
    setSelectedCategory(cat_sel);
    setServicesForCategory(selected_services); //salvo i servizi
    setFilteredServices(selected_services); // salvo i servizi dove carica tutto
  };

  const handleServiceSelection = (ids: number[], serv_sel: any) => {
    setFilter("");
    setStep(step + 1);
    let selected_providers = providers.filter(
      (el) => ids.indexOf(el.id) !== -1
    );
    setSelectedService(serv_sel);
    setFilteredServices(servicesForCategory);
    setProvidersForService(selected_providers);
    setFilteredProviders(selected_providers);
  };

  const handleProviderSelection = (provider: any) => {
    setFilter("");

    setAvaiableSlots([]);
    setSelectedProvider(provider);
    setFilteredProviders(providersForService);
    loadMonthAvaiability(
      moment(new Date()).startOf("month").format("YYYY-MM-DD"),
      moment(new Date()).endOf("month").format("YYYY-MM-DD"),
      provider.id
    );
    setStep(step + 1);
  };

  const loadAppointments = async (date: Date) => {
    setLoadingSlots(true);
    const res_services = await api.post("/SB/REST", {
      url: "/admin/schedule/available-slots",
      method: "get",
      params: {
        date: convertDateToInput(date),
        provider_id: selectedProvider.id,
        service_id: selectedService.id,
      },
    });

    let cartDates = cart
      .filter(
        (el) =>
          el.provider == selectedProvider.id &&
          el.date == convertDateToInput(date)
      )
      .map((el) => el.time);

    setAvaiableSlots(
      res_services.data.filter(
        (el: { time: string | undefined }) => cartDates.indexOf(el.time) === -1
      )
    );
    setLoadingSlots(false);
    setFilter("");
  };

  const loadMonthAvaiability = async (
    start: string,
    end: string,
    provider?: number
  ) => {
    setLoadingCalendar(true);
    const res_date = await api.post("/SB/REST", {
      url: `/admin/schedule?date_from=${start}&date_to=${end}&provider_id=${
        provider ? provider : selectedProvider.id
      }&service_id=${selectedService.id}`,
      method: "get",
    });
    let dates_temp = res_date.data
      .filter((el: any) => !el.is_day_off)
      .map((el: any) => el.date);
    let dates = [];
    const res_services = [];
    for (let i = 0; i < dates_temp.length; i++) {
      res_services[i] = api.post("/SB/REST", {
        url: "/admin/schedule/available-slots",
        method: "get",
        params: {
          date: convertDateToInput(dates_temp[i]),
          provider_id: selectedProvider.id,
          service_id: selectedService.id,
        },
      });
    }
    const results = await Promise.all(res_services);
    for (let i = 0; i < dates_temp.length; i++) {
      if (results[i].data.length != 0) {
        dates.push(dates_temp[i]);
      }
    }
    setWorkingDate(dates);
    setFilter("");
    setLoadingCalendar(false);
  };

  const handleBook = async () => {
    let desc = "";
    let totalPrice = 0;

    let res = await api.post("/securebook", { user: user });

    let c = [...cart];
    try {
      for (let i = 0; i < cart.length; i++) {
        const result = await api.post("/SB/JSONRP", {
          url: "https://user-api.simplybook.me/",
          method: "book",

          params: [
            cart[i].service,
            cart[i].provider,
            cart[i].date,
            cart[i].time,
            {
              client_id: user.id,
              client_sign: res.data, //md5(clientid+clienthash+secret_apiKey)
            },
            {
              handle_invoice: true,
            },
          ],

          //unitId provider
        });
        c[i].code_booking = result.data.bookings[0].code;
        totalPrice += cart[i].price ?? 0;
        desc +=
          cart[i].service_name +
          " - (" +
          convertDateToUser(cart[i].date) +
          " " +
          cart[i].time +
          ", " +
          result.data.bookings[0].code +
          ") ";
        SetAppointment((appointment: any) => [...appointment, result.data]);
      }
      setCart(c);
      SetDescription(desc);

      const res_services = await api.post("/create-payment-intent", {
        description: desc,
        price: totalPrice,
      });
      setClientSecret(res_services.data.clientSecret);

      setConfirmBook(true);
    } catch {
      alert(
        "Attenzione, si è verificato un errore durante la fase di prenotazione"
      );
    }
  };

  const handleBookConfirmation = async (type: string) => {
    for (let i = 0; i < appointment.length; i++) {
      const result = await api.post("/SB/JSONRP", {
        url: "https://user-api.simplybook.me/admin",
        type: "admin",
        method: "confirmInvoice",
        params: [appointment[i].invoice.id, type],

        //unitId provider
      });
    }

    const transactionId = `TRANS_${new Date().getTime()}/${appointment.map(
      (el: any) => el.invoice.id + "-"
    )}`; //transaction id

    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: "purchase", // Custom event name
        eventCategory: "Button", // Category of the event
        eventAction: "Click", // Action of the event
        eventLabel: "Pagamento da Booking Custom", // Optional label for the event
        value: cart.reduce(
          (sum, currentValue) => sum + (currentValue.price ?? 0),
          0
        ), // Optional value associated with the event
        //tax: 4.90,
        // shipping: 5.99,
        currency: "EUR",
        transaction_id: transactionId,
        coupon: promoCodeApplied ? promoCode : "",
      });
      console.log("GTM event pushed!");
    } else {
      console.warn("GTM dataLayer is not available.");
    }

    setStep(step + 1);
  };

  const handleBlockSlotFromSurvey = async (
    provider_id: number,
    service_id: number,
    date: any,
    time: any,
    service_name: string,
    provider_name: string,
    provider_image: string,
    price: any
  ) => {
    setCart((cart) => [
      ...cart,
      {
        provider: provider_id,
        service: service_id,
        date: date,
        time: time,
        service_name: service_name,
        provider_name: provider_name,
        provider_image: "https://booking.mamamind.it/" + provider_image,
        price: price,
      },
    ]);
  };

  const handleBlockSlot = async (el: any) => {
    setCart((cart) => [
      ...cart,
      {
        provider: selectedProvider.id,
        service: selectedService.id,
        date: el.date,
        time: el.time,
        service_name: selectedService.name,
        provider_name: selectedProvider.name,
        provider_image:
          "https://booking.mamamind.it/" + selectedProvider.picture_preview,
        price: selectedService.price,
      },
    ]);

    if (user) {
      setStep(step + 3);
    } else {
      setStep(step + 1);
    }
  };

  const handleAddNewAppointment = () => {
    setStep(0);
    setSelectedCategory(null);
    setSelectedProvider(null);
    setAvaiableSlots([]);
  };

  const handleTestCode = async () => {
    if (promoCodeApplied) {
      alert("Attenzione hai già applicato un codice promo");
    }

    try {
      let code_to_applay = promoCode; //copio il codice

      if (promoCode == "MamaMind50") {
        const res_date = await api.post("/Checkpromocode", {
          //funzione che controllerà tutti i vari code
          client_id: sessionStorage.getItem("iufpc"),
          promo: "MamaMind50",
          invoice: appointment[0].invoice.id, // id da passare serve poi
        });

        //se applicato allora eseguo il giro solito
        if (res_date.data.applicabile) {
          let c = [...cart];
          c[0].price = res_date.data.amount;

          setCart(c);
          setPromoCodeApplied(true);
        } else {
          alert("Attenzione, non puoi applicare questo codice");
        }
      } else {
        const res_date = await api.post("/SB/REST", {
          url: `/admin/invoices/${appointment[0].invoice.id}/apply-promo-code`,
          method: "put",
          params: {
            code: code_to_applay,
          },
        });

        //aggiorno il carrello, qua va ricreato poi stripe
        let c = [...cart];
        c[0].price = res_date.data.amount;

        setCart(c);
        setPromoCodeApplied(true);
      }
    } catch {
      alert("ERRORE, CODICE PROMO NON TROVATO");
    }
  };

  const removeFromCart = (index: number) => {
    let c = [...cart];
    c.splice(index, 1);
    setCart(c);
  };

  const renderElement = (): ReactNode => {
    switch (step) {
      case 0: //categories
        return (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 p-x-2">
              {filteredCategories.map((element) => (
                <Card
                  el={{
                    onClick: () =>
                      handleCategorySelection(element.events, element),
                    title: element.name,
                    img:
                      "https://mamamind.secure.simplybook.it/" +
                      element.picture_path,
                    description: element.description.replace(/<[^>]+>/g, ""),
                    button: true,
                  }}
                />
              ))}
            </div>
          </>
        );

      case 1: //services
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 p-x-2">
            {filteredServices.map((element) => (
              <Card
                el={{
                  onClick: () =>
                    handleServiceSelection(element.providers, element),
                  price: element.price,
                  duration: element.duration,
                  title: element.name,
                  img: element.picture_preview
                    ? "https://mamamind.secure.simplybook.it/" +
                      element.picture_preview
                    : "PINO",
                  description: element.description.replace(/<[^>]+>/g, ""),
                  button: true,
                }}
              />
            ))}
          </div>
        );

      case 2: //provider
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 p-x-2">
            {" "}
            {filteredProviders.map((element) => (
              <Card
                el={{
                  onClick: () => handleProviderSelection(element),
                  title: element.name,
                  circle: true,
                  img:
                    "https://mamamind.secure.simplybook.it/" +
                    element.picture_preview,
                  description: "",
                  button: true,
                }}
              />
            ))}
          </div>
        );

      case 3: //slot
        return (
          <>
            <div className="flex flex-col flex-wrap w-full overflow-hidden  justify-center font-bold gap-2 items-center">
              <CartCard
                el={{
                  img:
                    "https://mamamind.secure.simplybook.it/" +
                    selectedProvider.picture_preview,
                  prod_name: selectedService.name,
                  name: selectedProvider.name,
                }}
              />
              {/* <Card el={{ onClick: () => handleProviderSelection(selectedProvider), title: selectedProvider.name, img: "https://book.mamamind.it/" + selectedProvider.picture_preview, description: "", button: false }} /> */}
              <div>
                <div className="bg-white rounded-md border border-mamarosa">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="it"
                  >
                    <DateCalendar
                      disablePast
                      onChange={(el) => {
                        setSelectedDate(new Date(el));
                        loadAppointments(new Date(el));
                      }}
                      onMonthChange={(el) =>
                        loadMonthAvaiability(
                          el.startOf("month").format("YYYY-MM-DD"),
                          el.endOf("month").format("YYYY-MM-DD")
                        )
                      }
                      shouldDisableDate={(d) => {
                        return (
                          workingDate.indexOf(
                            moment(d).format("YYYY-MM-DD")
                          ) === -1
                        );
                      }}
                      loading={loadingCalendar}
                      renderLoading={() => (
                        <CircularProgress sx={{ color: "#008C95" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="flex flex-row flex-wrap w-full overflow-hidden  justify-center ">
                {loadingSlots ? (
                  <CircularProgress sx={{ color: "#008C95" }} />
                ) : (
                  avaiableSlots.map((el) => (
                    <div key={el.id} className="mb-2">
                      <button
                        key={el.id}
                        className="buttonOttanioChat mr-2"
                        onClick={() => {
                          setSelectedTime(el.time);
                          handleBlockSlot(el);
                        }}
                      >
                        {el.time.slice(0, -3)}
                      </button>
                    </div>
                  ))
                )}
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="flex flex-col flex-wrap w-full overflow-hidden  justify-center  font-bold gap-2 items-center">
              <Card
                el={{
                  onClick: () => console.log("Click"),
                  circle: true,
                  title: selectedProvider.name,
                  img:
                    "https://mamamind.secure.simplybook.it/" +
                    selectedProvider.picture_preview,
                  description: "",
                  button: false,
                }}
              />

              <p> {selectedService.name} </p>
              <p>Data: {convertDateToUser(selectedDate)}</p>
              <p>Orario: {selectedTime.slice(0, -3)}</p>
              <p>Per confermare l'appuntamento</p>
              <button
                onClick={() => {
                  setStep(step + 1);
                  setOperation("Registration");
                }}
                className="buttonOttanioChat mr-2 "
              >
                Registrati
              </button>
              <button
                onClick={() => {
                  setStep(step + 1);
                  setOperation("Login");
                }}
                className="buttonOttanioChat mr-2 "
              >
                Accedi
              </button>
            </div>
          </>
        );
      case 5:
        return (
          <>
            {operation == "Registration" ? (
              <RegistrationComponent
                setStep={setStep}
                setUser={setUser}
                step={step}
              />
            ) : (
              <LoginComponent
                step={step}
                setUser={setUser}
                setStep={setStep}
                handleBook={handleBook}
              />
            )}
          </>
        );
      case 6:
        return (
          <>
            <div className="flex flex-col w-full justify-center items-center font-bold gap-2  p-2">
              <p className="text-mamarosso font-bold ">Carrello</p>
              <div className="w-full md:w-2/3">
                <ul
                  role="list"
                  className="divide-y divide-gray-200 bg-white rounded-md gap-2"
                >
                  {cart.map((element, index) => (
                    <li className="py-3 sm:py-4">
                      <CartCard
                        el={{
                          img: element.provider_image,
                          prod_name: element.service_name,
                          name: element.provider_name,
                          date: convertDateToUser(element.date),
                          hour: element.time,
                          price: element.price,
                          delete_func: () => removeFromCart(index),
                          confirmBook: confirmBook,
                        }}
                      />
                    </li>
                  ))}

                  <li className="py-3 sm:py-4 p-2 flex flex-row-reverse">
                    TOTALE:{" "}
                    {cart.reduce(
                      (sum, currentValue) => sum + (currentValue.price ?? 0),
                      0
                    )}{" "}
                    €
                  </li>
                </ul>
              </div>

              {confirmBook ? (
                <>
                  <div className="w-full md:w-2/3 flex flex-row gap-2 ">
                    <input
                      type="text"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      placeholder="Codice Promozionale"
                      className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                    />
                    <button
                      onClick={async () => {
                        await handleTestCode();
                      }}
                      className="buttonOttanioChat mr-2 "
                    >
                      Applica{" "}
                    </button>
                  </div>
                  {cart.reduce(
                    (sum, currentValue) => sum + (currentValue.price ?? 0),
                    0
                  ) !== 0 ? (
                    <>
                      <div className="w-full md:w-2/3 ">
                        <PayPalScriptProvider options={initialOptions}>
                          <PayPalButtons
                            style={{
                              shape: "rect",
                              layout: "horizontal",
                              disableMaxWidth: true,
                              tagline: false,
                            }}
                            createOrder={async () => {
                              try {
                                let cart_to_send = cart.map((el) => {
                                  return {
                                    id: el.code_booking,
                                    quantity: "1",
                                    description: `${el.service_name} (${
                                      convertDateToUser(el.date) +
                                      " " +
                                      el.time +
                                      ", " +
                                      el.code_booking
                                    })`,
                                    price: el.price,
                                  };
                                });
                                const response = await fetch(
                                  "https://api.mamamind.it/api/orders",
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    // use the "body" param to optionally pass additional order information
                                    // like product ids and quantities
                                    body: JSON.stringify({
                                      cart: cart_to_send,
                                    }),
                                  }
                                );

                                const orderData = await response.json();

                                if (orderData.id) {
                                  return orderData.id;
                                } else {
                                  const errorDetail = orderData?.details?.[0];
                                  const errorMessage = errorDetail
                                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                    : JSON.stringify(orderData);

                                  throw new Error(errorMessage);
                                }
                              } catch (error) {
                                console.error(error);
                                setMessage(
                                  `Could not initiate PayPal Checkout...${error}`
                                );
                              }
                            }}
                            onApprove={async (data, actions) => {
                              try {
                                const response = await fetch(
                                  `https://api.mamamind.it/api/orders/${data.orderID}/capture`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                  }
                                );

                                const orderData = await response.json();
                                // Three cases to handle:
                                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                //   (2) Other non-recoverable errors -> Show a failure message
                                //   (3) Successful transaction -> Show confirmation or thank you message

                                const errorDetail = orderData?.details?.[0];

                                if (
                                  errorDetail?.issue === "INSTRUMENT_DECLINED"
                                ) {
                                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                  return actions.restart();
                                } else if (errorDetail) {
                                  // (2) Other non-recoverable errors -> Show a failure message
                                  throw new Error(
                                    `${errorDetail.description} (${orderData.debug_id})`
                                  );
                                } else {
                                  // (3) Successful transaction -> Show confirmation or thank you message
                                  // Or go to another URL:  actions.redirect('thank_you.html');
                                  const transaction =
                                    orderData.purchase_units[0].payments
                                      .captures[0];
                                  setMessage(
                                    `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                                  );

                                  await handleBookConfirmation("paypal");
                                  setStep(step + 1);
                                }
                              } catch (error) {
                                console.error(error);
                                setMessage(
                                  `Sorry, your transaction could not be processed...${error}`
                                );
                              }
                            }}
                          />
                        </PayPalScriptProvider>
                      </div>

                      <div className="w-full md:w-2/3">
                        <Elements
                          stripe={stripePromise}
                          options={{ clientSecret }}
                        >
                          <CheckoutForm
                            setStep={setStep}
                            step={step}
                            handleBookConfirmation={handleBookConfirmation}
                          />
                        </Elements>
                      </div>
                    </>
                  ) : (
                    <button
                      onClick={async () => {
                        await handleBookConfirmation("");
                      }}
                      className=" buttonOttanioChat  mr-2 "
                    >
                      CONFERMA APPUNTAMENTO{" "}
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      handleAddNewAppointment();
                    }}
                    className="buttonOttanioChat mr-2 "
                  >
                    Aggiungi Visita
                  </button>
                  {cart.length > 0 ? (
                    <button
                      onClick={async () => {
                        await handleBook();
                        //setConfirmBook(true);
                      }}
                      className="buttonOttanioChat  mr-2 "
                    >
                      Conferma e procedi al pagamento
                    </button>
                  ) : (
                    ""
                  )}{" "}
                  {/*SE non ho elementi nel carrelo rimuovo*/}
                </>
              )}
            </div>
          </>
        );

      case 7:
        return (
          <>
            <div className="flex flex-col w-full justify-center items-center font-bold gap-2  p-2">
              <p className=" text-mamarosso font-bold ">
                GRAZIE PER AVER PRENOTATO
              </p>
              <p className=" text-mamarosso font-bold ">
                Riceverai una mail di conferma a breve.
              </p>

              <button
                type="submit"
                className="buttonOttanioChat mr-2"
                onClick={() => navigate("/booking")}
              >
                Prenota di nuovo
              </button>
            </div>
          </>
        );
    }
  };

  return (
    <>
      {/* <Header  />*/}
      <div className="bg-cover h-[calc(100vh_-_80px)] flex-col flex justify-center items-center w-full gap-2 p-4 ">
        <Stepper step={step} setStep={setStep} />
        {step < 3 ? (
          <div className="pt-2 relative mx-auto w-full">
            <input
              value={filter}
              className=" bg-white h-10 px-5 pr-16 rounded-md w-full focus:outline-none border border-mamarosa"
              onChange={(e) => {
                filterData(e.target.value);
                setFilter(e.target.value);
              }}
              type="search"
              name="Cerca"
              placeholder="Cerca"
            />
            <button type="submit" className="absolute right-0 top-0 mt-5 mr-4">
              <svg
                className="text-gray-600 h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 56.966 56.966"
                width="512px"
                height="512px"
              >
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </div>
        ) : (
          ""
        )}
        <div className={"h-full  overflow-auto no-scrollbar w-full md:p-0"}>
          {loading ? (
            <div className="flex w-full justify-center items-center h-full">
              <CircularProgress sx={{ color: "#008C95" }} />
            </div>
          ) : (
            renderElement()
          )}
        </div>
      </div>
    </>
  );
}

function LoginComponent({
  setStep,
  setUser,
  step,
  handleBook,
}: {
  setStep: Function;
  setUser: Function;
  step: number;
  handleBook: Function;
}) {
  const [userCreated, setUserCreated] = useState<boolean>(false);

  const schema = yup.object({
    email: yup
      .string()
      .email("Inserisci una mail valida")
      .required("Campo obbligatorio"),
    password: yup.string().required("Campo obbligatorio"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: { email: string; password: string }) => {
    // https://user-api-v2.simplybook.me/

    //primo step creo
    try {
      const result = await api.post("/SB/JSONRP", {
        url: "https://user-api.simplybook.me/",
        method: "getClientInfoByLoginPassword",
        params: {
          login: data.email,
          password: data.password,
        },
      });

      setUserCreated(true);
      setUser(result.data);
      sessionStorage.setItem("iufpc", result.data.id);
      // handleBook(result)
      setStep(step + 1);
    } catch (e) {
      alert("Username o password errate");
    }
  };

  return (
    <div
      className={
        "col-span-4 h-full  w-full justify-center items-center flex bg-cover  p-4 gap-2 "
      }
    >
      {userCreated ? (
        <>
          <div className="grid grid-cols-2 gap-2 p-3 bg-white shadow-md rounded-md border border-mamarosa"></div>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-2 p-3 bg-white shadow-md rounded-md border border-mamarosa">
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Email
                </label>
                <input
                  {...register("email")}
                  type="text"
                  placeholder="Email"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.email?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Password
                </label>
                <input
                  {...register("password")}
                  type="password"
                  placeholder="Password"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.password?.message}</p>
              </div>
            </div>

            <div className="col-span-2 justify-self-center ">
              <button type="submit" className="buttonOttanioChat mr-2">
                Login
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

function RegistrationComponent({
  setStep,
  setUser,
  step,
}: {
  setStep: Function;
  setUser: Function;
  step: number;
}) {
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [termini, setTermini] = useState<boolean>(false);
  const [checkTermini, setCheckTermini] = useState<boolean>(false);

  const schema = yup.object({
    name: yup.string().required("Campo obbligatorio"),
    cognome: yup.string().required("Campo obbligatorio"),
    email: yup
      .string()
      .email("Inserisci una mail valida")
      .required("Campo obbligatorio"),
    password: yup
      .string()
      .min(8, "La password deve essere di almeno 8 caratteri")
      .required("Campo obbligatorio"),
    phone: yup.number().required("Campo obbligatorio"),
    codice_fiscale: yup
      .string()
      .min(16, "Minimo 16 caratteri")
      .max(16, "Massimo 16 caratteri")
      .matches(
        /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i,
        "Inserisci un codice fiscale valido!"
      )
      .required(), //una stringa personalizza l'errore,
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Le password devono corrispondere")
      .required("Campo obbligatorio"), //oneOf top per controllare se simili
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email")], "Le email devono corrispondere")
      .required("Campo obbligatorio"),
    indirizzo: yup.string().required(),
    cap: yup.number().required(),
    citta: yup.string().required(),
    paese: yup.string().required(),
    prefisso: yup.number().required("Campo obbligatorio"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
  });

  //so 2 steps. One create the user then update
  const onSubmit = async (data: User) => {
    // https://user-api-v2.simplybook.me/
    if (!termini) {
      setCheckTermini(true);
      alert("Per proseguire devi accettare i termini e le condizioni");
      return;
    } else {
      setCheckTermini(false);
    }
    if (isLoading) {
      //check se funzione già lanciata
      return;
    }
    //primo step creo
    try {
      setIsLoading(true);

      const result = await api.post("/SB/JSONRP", {
        url: "https://user-api.simplybook.me/",
        method: "signUpClient",
        params: {
          clientData: {
            email: data.email,
            name: data.name + " " + data.cognome,
            phone: "+" + data.prefisso + "" + data.phone,
            password: data.password,
          },
          sendEmail: true,
        },

        //unitId provider
      });

      let user_id: number = result.data.id;
      sessionStorage.setItem("iufpc", user_id.toString());
      const res_services = await api.post("/SB/REST", {
        url: `/admin/clients/field-values/${user_id}`,
        method: "put",
        params: {
          id: null,
          fields: [
            {
              id: "name",
              value: data.name + " " + data.cognome,
            },
            {
              id: "email",
              value: data.email,
            },
            {
              id: "phone",
              value: "+" + data.prefisso + "" + data.phone,
            },
            {
              id: "address1",
              value: data.indirizzo,
            },
            {
              id: "city",
              value: data.citta,
            },
            {
              id: "country_id",
              value: data.paese,
            },
            {
              id: "zip",
              value: data.cap,
            },
            {
              id: "16d040bc92c77ac80dcd2e34f4964fb2",
              value: data.codice_fiscale,
            },
            { id: "a254a76e04893cf37d9a7660373090c0", value: privacy },
            {
              id: "password",
              value: data.password,
            },
          ],
        },
      });

      setUser(result.data);
      setUserCreated(true);
      setIsLoading(false);
      setStep(step + 1);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response && e.response.status === 500) {
          let errorBody = e.response.data.error;

          if (e.response.data.error == "Client with this email already exist") {
            errorBody = "Questa mail risulta già associata ad un altro utente.";
          }
          alert(errorBody);
        } else {
          // Gestione di altri errori
          console.error("Errore durante la chiamata API:", e);
          alert(`Errore: ${e.message}`);
        }
      }
    }
  };

  return (
    <div
      className={
        "col-span-4 h-full  w-full justify-center items-center flex bg-cover  p-4 gap-2 "
      }
    >
      {userCreated ? (
        <>
          <div className="grid grid-cols-2 gap-2 p-3 bg-white shadow-md rounded-md">
            <div className="col-span-2 justify-self-center">
              Registrazione avvenuta con successo, controlla la mail!
            </div>
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-2 p-3 bg-white shadow-md rounded-md border border-mamarosa">
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Nome
                </label>
                <input
                  {...register("name")}
                  type="text"
                  placeholder="Nome"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.name?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Cognome
                </label>
                <input
                  {...register("cognome")}
                  type="text"
                  placeholder="Cognome"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.cognome?.message}</p>
              </div>
            </div>

            <div className="col-span-2 md:col-span-1">
              <div className="grid grid-cols-4 gap-2">
                <div className="mb-5 col-span1">
                  <label
                    htmlFor="fName"
                    className="mb-3  text-base font-medium text-mamarosso"
                  >
                    Prefisso
                  </label>
                  <select
                    {...register("prefisso")}
                    className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                  >
                    <option value="+93">Afghanistan (+93)</option>
                    <option value="+355">Albania (+355)</option>
                    <option value="+213">Algeria (+213)</option>
                    <option value="+1684">Samoa Americane (+1684)</option>
                    <option value="+376">Andorra (+376)</option>
                    <option value="+244">Angola (+244)</option>
                    <option value="+1264">Anguilla (+1264)</option>
                    <option value="+672">Antartide (+672)</option>
                    <option value="+1268">Antigua e Barbuda (+1268)</option>
                    <option value="+54">Argentina (+54)</option>
                    <option value="+374">Armenia (+374)</option>
                    <option value="+297">Aruba (+297)</option>
                    <option value="+61">Australia (+61)</option>
                    <option value="+43">Austria (+43)</option>
                    <option value="+994">Azerbaigian (+994)</option>
                    <option value="+1242">Bahamas (+1242)</option>
                    <option value="+973">Bahrein (+973)</option>
                    <option value="+880">Bangladesh (+880)</option>
                    <option value="+1246">Barbados (+1246)</option>
                    <option value="+375">Bielorussia (+375)</option>
                    <option value="+32">Belgio (+32)</option>
                    <option value="+501">Belize (+501)</option>
                    <option value="+229">Benin (+229)</option>
                    <option value="+1441">Bermuda (+1441)</option>
                    <option value="+975">Bhutan (+975)</option>
                    <option value="+591">Bolivia (+591)</option>
                    <option value="+387">Bosnia ed Erzegovina (+387)</option>
                    <option value="+267">Botswana (+267)</option>
                    <option value="+55">Brasile (+55)</option>
                    <option value="+246">
                      Territori britannici dell'oceano Indiano (+246)
                    </option>
                    <option value="+1284">
                      Isole Vergini britanniche (+1284)
                    </option>
                    <option value="+673">Brunei (+673)</option>
                    <option value="+359">Bulgaria (+359)</option>
                    <option value="+226">Burkina Faso (+226)</option>
                    <option value="+257">Burundi (+257)</option>
                    <option value="+855">Cambogia (+855)</option>
                    <option value="+237">Camerun (+237)</option>
                    <option value="+1">Canada (+1)</option>
                    <option value="+238">Capo Verde (+238)</option>
                    <option value="+1345">Isole Cayman (+1345)</option>
                    <option value="+236">
                      Repubblica Centrafricana (+236)
                    </option>
                    <option value="+235">Ciad (+235)</option>
                    <option value="+56">Cile (+56)</option>
                    <option value="+86">Cina (+86)</option>
                    <option value="+61">Isola di Natale (+61)</option>
                    <option value="+61">Isole Cocos (+61)</option>
                    <option value="+57">Colombia (+57)</option>
                    <option value="+269">Comore (+269)</option>
                    <option value="+682">Isole Cook (+682)</option>
                    <option value="+506">Costa Rica (+506)</option>
                    <option value="+385">Croazia (+385)</option>
                    <option value="+53">Cuba (+53)</option>
                    <option value="+599">Curacao (+599)</option>
                    <option value="+357">Cipro (+357)</option>
                    <option value="+420">Repubblica Ceca (+420)</option>
                    <option value="+243">
                      Repubblica Democratica del Congo (+243)
                    </option>
                    <option value="+45">Danimarca (+45)</option>
                    <option value="+253">Gibuti (+253)</option>
                    <option value="+1767">Dominica (+1767)</option>
                    <option value="+1809">Repubblica Dominicana (+1809)</option>
                    <option value="+670">Timor Est (+670)</option>
                    <option value="+593">Ecuador (+593)</option>
                    <option value="+20">Egitto (+20)</option>
                    <option value="+503">El Salvador (+503)</option>
                    <option value="+240">Guinea Equatoriale (+240)</option>
                    <option value="+291">Eritrea (+291)</option>
                    <option value="+372">Estonia (+372)</option>
                    <option value="+251">Etiopia (+251)</option>
                    <option value="+500">Isole Falkland (+500)</option>
                    <option value="+298">Isole Faroe (+298)</option>
                    <option value="+679">Figi (+679)</option>
                    <option value="+358">Finlandia (+358)</option>
                    <option value="+33">Francia (+33)</option>
                    <option value="+689">Polinesia Francese (+689)</option>
                    <option value="+241">Gabon (+241)</option>
                    <option value="+220">Gambia (+220)</option>
                    <option value="+995">Georgia (+995)</option>
                    <option value="+49">Germania (+49)</option>
                    <option value="+233">Ghana (+233)</option>
                    <option value="+350">Gibilterra (+350)</option>
                    <option value="+30">Grecia (+30)</option>
                    <option value="+299">Groenlandia (+299)</option>
                    <option value="+1473">Grenada (+1473)</option>
                    <option value="+1671">Guam (+1671)</option>
                    <option value="+502">Guatemala (+502)</option>
                    <option value="+441481">Guernsey (+441481)</option>
                    <option value="+224">Guinea (+224)</option>
                    <option value="+245">Guinea-Bissau (+245)</option>
                    <option value="+592">Guyana (+592)</option>
                    <option value="+509">Haiti (+509)</option>
                    <option value="+504">Honduras (+504)</option>
                    <option value="+852">Hong Kong (+852)</option>
                    <option value="+36">Ungheria (+36)</option>
                    <option value="+354">Islanda (+354)</option>
                    <option value="+91">India (+91)</option>
                    <option value="+62">Indonesia (+62)</option>
                    <option value="+98">Iran (+98)</option>
                    <option value="+964">Iraq (+964)</option>
                    <option value="+353">Irlanda (+353)</option>
                    <option value="+441624">Isola di Man (+441624)</option>
                    <option value="+972">Israele (+972)</option>
                    <option value="+39" selected>
                      Italia (+39)
                    </option>
                    <option value="+225">Costa d'Avorio (+225)</option>
                    <option value="+1876">Giamaica (+1876)</option>
                    <option value="+81">Giappone (+81)</option>
                    <option value="+441534">Jersey (+441534)</option>
                    <option value="+962">Giordania (+962)</option>
                    <option value="+7">Kazakistan (+7)</option>
                    <option value="+254">Kenya (+254)</option>
                    <option value="+686">Kiribati (+686)</option>
                    <option value="+383">Kosovo (+383)</option>
                    <option value="+965">Kuwait (+965)</option>
                    <option value="+996">Kirghizistan (+996)</option>
                    <option value="+856">Laos (+856)</option>
                    <option value="+371">Lettonia (+371)</option>
                    <option value="+961">Libano (+961)</option>
                    <option value="+266">Lesotho (+266)</option>
                    <option value="+231">Liberia (+231)</option>
                    <option value="+218">Libia (+218)</option>
                    <option value="+423">Liechtenstein (+423)</option>
                    <option value="+370">Lituania (+370)</option>
                    <option value="+352">Lussemburgo (+352)</option>
                    <option value="+853">Macao (+853)</option>
                    <option value="+389">Macedonia (+389)</option>
                    <option value="+261">Madagascar (+261)</option>
                    <option value="+265">Malawi (+265)</option>
                    <option value="+60">Malesia (+60)</option>
                    <option value="+960">Maldive (+960)</option>
                    <option value="+223">Mali (+223)</option>
                    <option value="+356">Malta (+356)</option>
                    <option value="+692">Isole Marshall (+692)</option>
                    <option value="+222">Mauritania (+222)</option>
                    <option value="+230">Mauritius (+230)</option>
                    <option value="+262">Mayotte (+262)</option>
                    <option value="+52">Messico (+52)</option>
                    <option value="+691">Micronesia (+691)</option>
                    <option value="+373">Moldavia (+373)</option>
                    <option value="+377">Monaco (+377)</option>
                    <option value="+976">Mongolia (+976)</option>
                    <option value="+382">Montenegro (+382)</option>
                    <option value="+1664">Montserrat (+1664)</option>
                    <option value="+212">Marocco (+212)</option>
                    <option value="+258">Mozambico (+258)</option>
                    <option value="+95">Myanmar (+95)</option>
                    <option value="+264">Namibia (+264)</option>
                    <option value="+674">Nauru (+674)</option>
                    <option value="+977">Nepal (+977)</option>
                    <option value="+31">Paesi Bassi (+31)</option>
                    <option value="+687">Nuova Caledonia (+687)</option>
                    <option value="+64">Nuova Zelanda (+64)</option>
                    <option value="+505">Nicaragua (+505)</option>
                    <option value="+227">Niger (+227)</option>
                    <option value="+234">Nigeria (+234)</option>
                    <option value="+683">Niue (+683)</option>
                    <option value="+850">Corea del Nord (+850)</option>
                    <option value="+1670">
                      Isole Marianne Settentrionali (+1670)
                    </option>
                    <option value="+47">Norvegia (+47)</option>
                    <option value="+968">Oman (+968)</option>
                    <option value="+92">Pakistan (+92)</option>
                    <option value="+680">Palau (+680)</option>
                    <option value="+970">Palestina (+970)</option>
                    <option value="+507">Panama (+507)</option>
                    <option value="+675">Papua Nuova Guinea (+675)</option>
                    <option value="+595">Paraguay (+595)</option>
                    <option value="+51">Perù (+51)</option>
                    <option value="+63">Filippine (+63)</option>
                    <option value="+64">Pitcairn (+64)</option>
                    <option value="+48">Polonia (+48)</option>
                    <option value="+351">Portogallo (+351)</option>
                    <option value="+1">Porto Rico (+1)</option>
                    <option value="+974">Qatar (+974)</option>
                    <option value="+242">Repubblica del Congo (+242)</option>
                    <option value="+40">Romania (+40)</option>
                    <option value="+7">Russia (+7)</option>
                    <option value="+250">Ruanda (+250)</option>
                    <option value="+262">Riunione (+262)</option>
                    <option value="+599">Saint Barthelemy (+599)</option>
                    <option value="+290">Sant'Elena (+290)</option>
                    <option value="+1869">Saint Kitts e Nevis (+1869)</option>
                    <option value="+1758">Santa Lucia (+1758)</option>
                    <option value="+590">Saint Martin (+590)</option>
                    <option value="+508">Saint Pierre e Miquelon (+508)</option>
                    <option value="+1784">
                      Saint Vincent e Grenadine (+1784)
                    </option>
                    <option value="+685">Samoa (+685)</option>
                    <option value="+378">San Marino (+378)</option>
                    <option value="+239">Sao Tome e Principe (+239)</option>
                    <option value="+966">Arabia Saudita (+966)</option>
                    <option value="+221">Senegal (+221)</option>
                    <option value="+381">Serbia (+381)</option>
                    <option value="+248">Seychelles (+248)</option>
                    <option value="+232">Sierra Leone (+232)</option>
                    <option value="+65">Singapore (+65)</option>
                    <option value="+1721">Sint Maarten (+1721)</option>
                    <option value="+421">Slovacchia (+421)</option>
                    <option value="+386">Slovenia (+386)</option>
                    <option value="+677">Isole Salomone (+677)</option>
                    <option value="+252">Somalia (+252)</option>
                    <option value="+27">Sudafrica (+27)</option>
                    <option value="+82">Corea del Sud (+82)</option>
                    <option value="+211">Sud Sudan (+211)</option>
                    <option value="+34">Spagna (+34)</option>
                    <option value="+94">Sri Lanka (+94)</option>
                    <option value="+249">Sudan (+249)</option>
                    <option value="+597">Suriname (+597)</option>
                    <option value="+268">Swaziland (+268)</option>
                    <option value="+46">Svezia (+46)</option>
                    <option value="+41">Svizzera (+41)</option>
                    <option value="+963">Siria (+963)</option>
                    <option value="+886">Taiwan (+886)</option>
                    <option value="+992">Tagikistan (+992)</option>
                    <option value="+255">Tanzania (+255)</option>
                    <option value="+66">Thailandia (+66)</option>
                    <option value="+228">Togo (+228)</option>
                    <option value="+690">Tokelau (+690)</option>
                    <option value="+676">Tonga (+676)</option>
                    <option value="+1868">Trinidad e Tobago (+1868)</option>
                    <option value="+216">Tunisia (+216)</option>
                    <option value="+90">Turchia (+90)</option>
                    <option value="+993">Turkmenistan (+993)</option>
                    <option value="+1649">Isole Turks e Caicos (+1649)</option>
                    <option value="+688">Tuvalu (+688)</option>
                    <option value="+1340">
                      Isole Vergini americane (+1340)
                    </option>
                    <option value="+256">Uganda (+256)</option>
                    <option value="+380">Ucraina (+380)</option>
                    <option value="+971">Emirati Arabi Uniti (+971)</option>
                    <option value="+44">Regno Unito (+44)</option>
                    <option value="+1">Stati Uniti (+1)</option>
                    <option value="+598">Uruguay (+598)</option>
                    <option value="+998">Uzbekistan (+998)</option>
                    <option value="+678">Vanuatu (+678)</option>
                    <option value="+379">Vaticano (+379)</option>
                    <option value="+58">Venezuela (+58)</option>
                    <option value="+84">Vietnam (+84)</option>
                    <option value="+681">Wallis e Futuna (+681)</option>
                    <option value="+212">Sahara Occidentale (+212)</option>
                    <option value="+967">Yemen (+967)</option>
                    <option value="+260">Zambia (+260)</option>
                    <option value="+263">Zimbabwe (+263)</option>
                  </select>
                  <p>{errors.paese?.message}</p>
                </div>
                <div className="mb-5 col-span-3">
                  <label
                    htmlFor="fName"
                    className="mb-3  text-base font-medium text-mamarosso"
                  >
                    Telefono
                  </label>
                  <input
                    type="text"
                    {...register("phone")}
                    placeholder="Telefono"
                    className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                  />
                  <p>{errors.phone?.message}</p>
                </div>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Codice Fiscale
                </label>
                <input
                  {...register("codice_fiscale")}
                  type="text"
                  placeholder="Codice Fiscale"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.codice_fiscale?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Indirizzo
                </label>
                <input
                  {...register("indirizzo")}
                  type="text"
                  placeholder="Indirizzo"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.indirizzo?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  CAP
                </label>
                <input
                  {...register("cap")}
                  type="text"
                  placeholder="CAP"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.cap?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Città
                </label>
                <input
                  {...register("citta")}
                  type="text"
                  placeholder="Città"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.citta?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Paese
                </label>
                <select
                  {...register("paese")}
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                >
                  <option value="AF">Afghanistan</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AG">Antigua e Barbuda</option>
                  <option value="SA">Arabia Saudita</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaigian</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrein</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BE">Belgio</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BT">Bhutan</option>
                  <option value="BY">Bielorussia</option>
                  <option value="MM">Birmania</option>
                  <option value="BO">Bolivia</option>
                  <option value="BA">Bosnia ed Erzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BR">Brasile</option>
                  <option value="BN">Brunei</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambogia</option>
                  <option value="CM">Camerun</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Capo Verde</option>
                  <option value="TD">Ciad</option>
                  <option value="CL">Cile</option>
                  <option value="CN">Cina</option>
                  <option value="CY">Cipro</option>
                  <option value="VA">Città del Vaticano</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comore</option>
                  <option value="KP">Corea del Nord</option>
                  <option value="KR">Corea del Sud</option>
                  <option value="CI">Costa d'Avorio</option>
                  <option value="CR">Costa Rica</option>
                  <option value="HR">Croazia</option>
                  <option value="CU">Cuba</option>
                  <option value="DK">Danimarca</option>
                  <option value="DM">Dominica</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egitto</option>
                  <option value="SV">El Salvador</option>
                  <option value="AE">Emirati Arabi Uniti</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="SZ">Eswatini</option>
                  <option value="ET">Etiopia</option>
                  <option value="FJ">Figi</option>
                  <option value="PH">Filippine</option>
                  <option value="FI">Finlandia</option>
                  <option value="FR">Francia</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germania</option>
                  <option value="GH">Ghana</option>
                  <option value="JM">Giamaica</option>
                  <option value="JP">Giappone</option>
                  <option value="JO">Giordania</option>
                  <option value="GR">Grecia</option>
                  <option value="GD">Grenada</option>
                  <option value="GT">Guatemala</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GQ">Guinea Equatoriale</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HN">Honduras</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Irlanda</option>
                  <option value="IS">Islanda</option>
                  <option value="IL">Israele</option>
                  <option value="IT" selected>
                    Italia
                  </option>
                  <option value="KZ">Kazakistan</option>
                  <option value="KE">Kenya</option>
                  <option value="KG">Kirghizistan</option>
                  <option value="KI">Kiribati</option>
                  <option value="KW">Kuwait</option>
                  <option value="LA">Laos</option>
                  <option value="LS">Lesotho</option>
                  <option value="LV">Lettonia</option>
                  <option value="LB">Libano</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libia</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lituania</option>
                  <option value="LU">Lussemburgo</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldive</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MA">Marocco</option>
                  <option value="MH">Marshall</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="MX">Messico</option>
                  <option value="FM">Micronesia</option>
                  <option value="MD">Moldavia</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MZ">Mozambico</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NO">Norvegia</option>
                  <option value="NZ">Nuova Zelanda</option>
                  <option value="OM">Oman</option>
                  <option value="NL">Paesi Bassi</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua Nuova Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Perù</option>
                  <option value="PL">Polonia</option>
                  <option value="PT">Portogallo</option>
                  <option value="QA">Qatar</option>
                  <option value="GB">Regno Unito</option>
                  <option value="CF">Repubblica Centrafricana</option>
                  <option value="CZ">Repubblica Ceca</option>
                  <option value="CD">Repubblica Democratica del Congo</option>
                  <option value="DO">Repubblica Dominicana</option>
                  <option value="RO">Romania</option>
                  <option value="RW">Ruanda</option>
                  <option value="RU">Russia</option>
                  <option value="KN">Saint Kitts e Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="VC">Saint Vincent e Grenadine</option>
                  <option value="SB">Salomone</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">São Tomé e Príncipe</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SY">Siria</option>
                  <option value="SK">Slovacchia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SO">Somalia</option>
                  <option value="ES">Spagna</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="US">Stati Uniti d'America</option>
                  <option value="ZA">Sudafrica</option>
                  <option value="SD">Sudan</option>
                  <option value="SS">Sudan del Sud</option>
                  <option value="SE">Svezia</option>
                  <option value="CH">Svizzera</option>
                  <option value="SR">Suriname</option>
                  <option value="TJ">Tagikistan</option>
                  <option value="TZ">Tanzania</option>
                  <option value="TH">Thailandia</option>
                  <option value="TL">Timor Est</option>
                  <option value="TG">Togo</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad e Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turchia</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UA">Ucraina</option>
                  <option value="UG">Uganda</option>
                  <option value="HU">Ungheria</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Vietnam</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                </select>
                <p>{errors.paese?.message}</p>
              </div>
            </div>
            <div className="col-span-2">
              <hr style={{ borderColor: "#e7bed0" }}></hr>
            </div>

            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Email
                </label>
                <input
                  {...register("email")}
                  type="text"
                  placeholder="Email"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.email?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Password
                </label>
                <input
                  {...register("password")}
                  type="password"
                  placeholder="Password"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.password?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Conferma Email
                </label>
                <input
                  {...register("confirmEmail")}
                  type="text"
                  placeholder="Conferma email"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.confirmEmail?.message}</p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="mb-5">
                <label
                  htmlFor="fName"
                  className="mb-3  text-base font-medium text-[#07074D]"
                >
                  Conferma Password
                </label>
                <input
                  {...register("confirmPassword")}
                  type="password"
                  placeholder="Conferma Password"
                  className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
                />
                <p>{errors.confirmPassword?.message}</p>
              </div>
            </div>
            <div className="col-span-2 justify-self-left mb-5">
              <div>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    className={
                      "form-checkbox h-5 w-5 rounded-full " +
                      (checkTermini ? " text-mamarosso" : " text-mamaottanio")
                    }
                    checked={termini}
                    onClick={() => setTermini(!termini)}
                  />
                  <span
                    className={
                      "ml-2 " +
                      (checkTermini ? " text-mamarosso" : " text-mamaottanio")
                    }
                  >
                    {" "}
                    <a
                      href="https://mamamind.it/condizioni-generali/"
                      target="_blank"
                    >
                      <span className="underline">
                        Acconsento ai termini e condizioni del servizio.*
                      </span>{" "}
                      {checkTermini && " Campo obbligatorio"}
                    </a>
                  </span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-mamaottanio rounded-full"
                    checked={privacy}
                    onClick={() => setPrivacy(!privacy)}
                  />
                  <span className="ml-2 text-mamaottanio underline">
                    <a
                      href="https://mamamind.it/privacy-policy/"
                      target="_blank"
                    >
                      Acconsento a ricevere messaggi di carattere promozionale.
                    </a>
                  </span>
                </label>
              </div>
            </div>
            <div className="col-span-2 justify-self-center ">
              <button type="submit" className="buttonOttanioChat mr-2">
                Registrati
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
