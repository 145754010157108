import { PaymentElement, AddressElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm({
  step,
  setStep,
  handleBookConfirmation,
}: {
  setStep: Function;
  step: number;
  handleBookConfirmation: Function;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {},
        redirect: "if_required",
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occured.");
        }
      } else {
        handleBookConfirmation("Stripe");
        setStep(step + 1);
      }

      setIsProcessing(false);

      //setIsProcessing(false);
    } catch (e) {
      console.log(e);
      alert("Errore durante il pagamento");
    }
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="bg-white p-4 rounded-md border border-mamarosa"
    >
      <PaymentElement id="payment-element" />
      <AddressElement options={{ mode: "billing" }} />
      <button
        className="buttonOttanioChat w-full mt-2"
        disabled={isProcessing || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Paga Ora"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
