import { useEffect, useState } from "react";
import {
  FaArrowLeftLong,
  FaArrowRightLong,
  FaCheck,
  FaMessage,
  FaX,
} from "react-icons/fa6";
import { MdCheck } from "react-icons/md";
import api from "../api/api";
import CartCard from "../components/CartCard";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import validator from "validator";
import isEmail from "validator/lib/isEmail";
import Header from "../components/Header";

const providers = [
  { nome: "Flavia Risari", approccio: 1, id: 1 },
  { nome: "Anna Greco", approccio: 1, id: 17 },
  { nome: "Elisa Giustarini", approccio: 2, id: 9 },
  { nome: "Marta Ferioli", approccio: 4, id: 16 },
  //{ nome: "Simona Marchesi", approccio: 1, id: 20 }, rimossa il 6/9/24
  { nome: "Francesca Danioni", approccio: 1, id: 52 },
  { nome: "Angela De Giorgio", approccio: 4, id: 29 },
  { nome: "Chiara Dragoni", approccio: 3, id: 77 },
  { nome: "Victoria Candian", approccio: 2, id: 83 },
  { nome: "Vanessa Scatti", approccio: 4, id: 89 },
  { nome: "Beatrice Allegri", approccio: 4, id: 7 },
  { nome: "Alice Aceto", approccio: 5, id: 27 },
  { nome: "Chiara Miretti", approccio: 2, id: 66 },
  { nome: "Cecilia Herskovits", approccio: 6, id: 5 },
  { nome: "Chiara Speranza", approccio: 1, id: 25 },
  { nome: "Luca Lavezzari", approccio: 4, id: 13 },
  { nome: "Francesca Brasca", approccio: 2, id: 14 },
  { nome: "Martina Del Balzo", approccio: 7, id: 15 },
  { nome: "Enza Donnici", approccio: 4, id: 65 },
  { nome: "Giulia Maggiali", approccio: 1, id: 70 },
  { nome: "Anna Cusmai", approccio: 4, id: 69 },
  { nome: "Elena Tagliabue", approccio: 2, id: 72 },
  { nome: "Roberta Macri", approccio: 5, id: 62 },
  { nome: "Valentina Vaia", approccio: 1, id: 18 },
  { nome: "Claudia Ambrosini", approccio: 2, id: 24 },
  { nome: "Silvia Delledonne", approccio: 1, id: 21 },
  { nome: "Francesca Pellegrini", approccio: 4, id: 11 },
  { nome: "Margherita Hassan", approccio: 8, id: 28 },
  { nome: "Roberta De Bellis", approccio: 2, id: 76 },
  { nome: "Lucrezia De Magistris", approccio: 1, id: 84 },
  { nome: "Vanessa Buono", approccio: 2, id: 86 },
  { nome: "Francesca Carlomagno", approccio: 2, id: 32 },
  { nome: "Virginia Antonietti", approccio: 2, id: 75 },
  { nome: "Emma Bassetti", approccio: 5, id: 2 },
  { nome: "Irene Manzoni", approccio: 1, id: 12 },
  { nome: "Lara Ranieri", approccio: 2, id: 22 },
  { nome: "Sonia Gemma", approccio: 9, id: 55 },
  { nome: "Vittoria Castagner", approccio: 4, id: 63 },
  { nome: "Elisa Reali", approccio: 10, id: 64 },
  { nome: "Simona Colamartino", approccio: 1, id: 74 },
  { nome: "Diana Maccarini", approccio: 2, id: 71 },
  { nome: "Immacolata Arzu", approccio: 11, id: 80 },
  { nome: "Margherita Lenzi", approccio: 1, id: 6 },
  { nome: "Chiara Rossi", approccio: 1, id: 68 },
  { nome: "Valeria Arrà", approccio: 6, id: 67 },
  { nome: "Mara Messina", approccio: 2, id: 79 },
];

const QuestionCard = ({ text, step, answers }) => {
  return (
    <>
      <div className="text-mamagiallo text-2xl font-bold mb-4 ">
        <span className="w-10 h-10 px-2 bg-[#ffc4c6] rounded-full text-[#AC005A]">
          {answers.length + 1}
        </span>{" "}
        {text}
      </div>
    </>
  );
};
const AnswerCard = ({ text, index, answers, step }) => {
  const [selected, setSelected] = useState(false);

  return (
    <>
      <div
        className={`bg-white rounded-md p-3 relative duration-500 cursor-pointer ${
          selected && " border border-[#008C95]"
        }`}
        key={index}
        onClick={() => {}}
      >
        {selected && (
          <div className="absolute -top-2 -right-2 rounded-md bg-[#008C95]">
            <MdCheck className="text-white text-lg" />
          </div>
        )}
        {text}
      </div>
    </>
  );
};

export const Stepper = () => {
  const [effect, setEffect] = useState(0);
  const [mandatory, setMandatory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [result, setResult] = useState("");
  const [completed, setCompleted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [path, setPath] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [servizi, setServizi] = useState([]);
  const [approccio, setApproccio] = useState(null);
  const [sesso, setSesso] = useState(false);
  const [periodi, setPeriodi] = useState(false);
  const [idSurvey, setIdSurvey] = useState(null);
  const [counter, setCounter] = useState(0);
  const [usePromo, setUsePromo] = useState(0);

  const [quiz, setQuiz] = useState([
    {
      id: 0,
      question: "Sei maggiorenne?",
      options: [
        { text: "Si", nextStep: 100, continue: true },
        { text: "No", nextStep: 12, continue: true },
      ],
    },
    {
      id: 100,
      question: "Per chi stai cercando supporto?",
      options: [
        { text: "Me stessa/o", nextStep: 1, continue: true },
        { text: "Per me e il/la mia/o partner", nextStep: 2, continue: true },
        { text: "Per mia/o figlia/o minorenne", nextStep: 3, continue: true },
      ],
    },
    {
      id: 1,
      question: "Quale servizio stai cercando?",
      options: [
        {
          text: "Supporto Psicologico",
          nextStep: 4,
          continue: true,
          path: "terapia",
        },
        {
          text: "Supporto Psichiatrico",
          nextStep: 1,
          continue: false,
          path: "psichiatrica",
        },
        { text: "Consulenza Ostetrica", nextStep: 13, continue: true },
        {
          text: "Consulenza Nutrizionale",
          nextStep: 1,
          continue: false,
          path: "nutrizionista",
        },
        {
          text: "Visita Ginecologica",
          nextStep: 1,
          continue: false,
          path: "ginecologia",
        },
        //{ text: "Mediazione Familiare", nextStep: 1, continue: false, path: "mediazione" },
        //{ text: "Supporto Genitoriale Pedagogico", nextStep: 1, continue: false, path: "pedagogico" },
      ],
    },
    {
      id: 2,
      question: "Quale servizio stai cercando?",
      options: [
        {
          text: "Supporto Psicologico",
          nextStep: 5,
          continue: true,
          path: "coppia_psicologico",
        },
        {
          text: "Mediazione Familiare",
          nextStep: 1,
          continue: false,
          path: "coppia_familiare",
        },
        //{ text: "Supporto Genitoriale Pedagogico", nextStep: 1, continue: false, path: "coppia_genitoriale" },
      ],
    },

    {
      id: 3,
      question:
        "Disclamer: Servizio rivolto solo ai maggiori di 14 anni. Per erogare il servizio dovremo ricevere il consenso informato compilato da entrambi i genitori/tutori legali.",
      options: [
        {
          text: "Sono un genitore, vorrei un supporto per mio/a figlio/a",
          nextStep: 8,
          continue: false,
          path: "supporto_figlia",
        },
        {
          text: "Sono un genitore, vorrei un supporto senza coinvolgere mia/o figlia/o nella seduta",
          nextStep: 9,
          continue: true,
        },
      ],
    },
    {
      id: 4,
      multiple: true,
      question:
        "Quali di queste categorie descrivono maggiormente la tua situazione? È possibile selezionare più di una opzione",
      options: [],
    },
    {
      id: 5,
      question: "Hai una preferenza sull'approccio?",
      options: [
        { text: "Indifferente", nextStep: 7, continue: true, id: 12 },
        { text: "Sistemico", nextStep: 7, continue: true, id: 1 },
        { text: "Psicodinamico", nextStep: 7, continue: true, id: 2 },
        { text: "Transculturale", nextStep: 7, continue: true, id: 3 },
        {
          text: "Cognitivo Costruttivista",
          nextStep: 7,
          continue: true,
          id: 4,
        },
        { text: "Cognitivo", nextStep: 7, continue: true, id: 5 },
        { text: "Integrato", nextStep: 7, continue: true, id: 6 },
        { text: "Breve focale", nextStep: 7, continue: true, id: 7 },
        {
          text: "Cognitivo Comportamentale",
          nextStep: 7,
          continue: true,
          id: 8,
        },
        {
          text: "Cognitivo Neuropsicologico",
          nextStep: 7,
          continue: true,
          id: 9,
        },
        { text: "Rogersiano", nextStep: 7, continue: true, id: 10 },
        {
          text: "Cognitivo Interpersonale",
          nextStep: 7,
          continue: true,
          id: 11,
        },
      ],
    },
    {
      id: 6,
      question: "Vorresti che la/il tua/tuo terapeuta/o sia",
      options: [
        { text: "Donna", nextStep: 7, continue: true },
        { text: "Uomo", nextStep: 7, continue: true },
        { text: "Indifferente", nextStep: 7, continue: true },
      ],
    },
    {
      id: 7,
      multiple: true,
      question:
        "In quale fascia oraria vorresti effettuare la seduta? Lasciare selezionare più di una opzione",
      options: [
        { text: "8-13", nextStep: 7, continue: false, id: 1 },
        { text: "13-18", nextStep: 7, continue: false, id: 2 },
        { text: "18-22", nextStep: 7, continue: false, id: 3 },
      ],
    },
    {
      id: 8,
      question:
        "Quali di queste categorie descrivono maggiormente la tua situazione? È possibile selezionare più di una opzione (le categorie da poter scegliere saranno leggermente diverse rispetto a quelle degli adulti",
      options: [
        { text: "Donna", nextStep: 7, continue: false },
        { text: "Uomo", nextStep: 7, continue: false },
        { text: "Indifferente", nextStep: 7, continue: false },
      ],
    },
    {
      id: 9,
      question:
        "Vorresti iniziare il percorso come singolo genitore o fare un percorso in due?",
      options: [
        { text: "Percorso individuale", nextStep: 10, continue: true },
        { text: "Percorso in due", nextStep: 11, continue: true },
      ],
    },
    {
      id: 10,
      question: "Quale servizio stai cercando?",
      options: [
        {
          text: "Supporto Psicologico",
          nextStep: 10,
          continue: false,
          path: "gen_singolo_psicologico",
        },
        //{ text: "Supporto Genitoriale Pedagogico", nextStep: 11, continue: false, path: "gen_singolo_pedagogico" },
      ],
    },
    {
      id: 11,
      question: "Quale servizio stai cercando?",
      options: [
        {
          text: "Supporto Psicologico",
          nextStep: 10,
          continue: false,
          path: "gen_coppia_psicologico",
        },
        //{ text: "Supporto Genitoriale Pedagogico", nextStep: 11, continue: false, path: "gen_coppia_pedagogico" },
      ],
    },
    {
      id: 12,
      question:
        "Disclamer: Servizio rivolto solo ai maggiori di 14 anni. Per erogare il servizio ai minori è necessario inviare un consenso informato compilato da entrambi i genitori/tutori legali.",
      options: [
        {
          text: "Non me la sento di parlarne con i miei genitori/tutori legali. Non è possibile per me inviare il consenso informato.",
          nextStep: 10,
          continue: false,
          path: "minorenne_no_gen",
        },
        {
          text: "È possibile per me coinvolgere i miei genitori/tutori legali e inviare il consenso informato (verrà condiviso dal/dalla terapeuta prima della prima seduta)",
          nextStep: 11,
          continue: false,
          path: "genitore_si_gen",
        },
      ],
    },
    {
      id: 13,
      question: "Quale servizio stai cercando?",
      options: [
        {
          text: "Puerperio e Assistenza al Neonato",
          nextStep: 63,
          continue: false,
          path: "puerperio",
        },
        {
          text: "Allattamento al seno",
          nextStep: 64,
          continue: false,
          path: "allattamento",
        },
        {
          text: "Gravidanza fisiologica e Percorso Nascita",
          nextStep: 62,
          continue: false,
          path: "gravidanza",
        },
      ],
    },
    {
      id: 14,
      question: "Disclaimer",
      options: [
        {
          text: "Supporto Psicologico",
          nextStep: 10,
          continue: false,
          path: "gen_coppia_psicologico",
        },
        //{ text: "Supporto Genitoriale Pedagogico", nextStep: 11, continue: false, path: "gen_coppia_pedagogico" },
      ],
    },
  ]);

  const resetEffect = () => {
    setTimeout(() => {
      setEffect(0);
    }, 800);
  };

  const renderFinalStep = () => {
    switch (path) {
      case "ginecologia":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={78}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );

      case "terapia":
        return (
          <RenderSpecialistaPsicologia
            usePromo={usePromo}
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            periodi={periodi}
            answers={answers}
            tipologia={15}
            servizi={servizi}
            approccio={approccio}
            sesso={sesso}
            handleBack={handleBack}
          />
        );
      case "psichiatrica":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={69}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "gravidanza":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={62}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "puerperio":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={63}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "allattamento":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={64}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "nutrizionista":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={32}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "mediazione":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={74}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "pedagogico":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={31}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "coppia_psicologico":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={22}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "coppia_familiare":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={60}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "coppia_genitoriale":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={61}
            handleBack={handleBack}
            servizi={servizi}
            periodi={periodi}
          />
        );
      case "supporto_figlia":
        return (
          <RenderSupportoFiglia
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            answers={answers}
            handleBack={handleBack}
            idSurvey={idSurvey}
          />
        );
      case "gen_singolo_psicologico":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={9}
            handleBack={handleBack}
          />
        );
      case "gen_singolo_pedagogico":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={31}
            handleBack={handleBack}
          />
        );
      case "gen_coppia_psicologico":
        return (
          <RenderSpecialista
            currentQuestionId={currentQuestion?.id}
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={76}
            handleBack={handleBack}
          />
        );
      case "gen_coppia_pedagogico":
        return (
          <RenderSpecialista
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            tipologia={61}
            handleBack={handleBack}
          />
        );
      case "minorenne_no_gen":
        return (
          <RenderMinoreNoGen
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            handleBack={handleBack}
          />
        );
      case "genitore_si_gen":
        return (
          <RenderMinoreSiGen
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            handleBack={handleBack}
          />
        );
      case "aiutami_a_decidere":
        return (
          <RenderMinoreNoGen
            counter={counter}
            idSurvey={idSurvey}
            answers={answers}
            handleBack={handleBack}
          />
        );
    }
  };

  useEffect(() => {
    async function getUsePromo() {
      // You can await here
      let res_usePromo = await api.get("/surveyPromo/usePromo");
      console.log(res_usePromo.data.usePromo);
      setUsePromo(res_usePromo.data.usePromo);
      // ...
    }
    getUsePromo();
  }, []);

  const insertIntoSurveyPromo = async () => {
    let res = await api.post("/surveyPromo", {
      id_survey: idSurvey,
      use_promo: usePromo,
    });
  };

  //#region
  const handleNext = async () => {
    if (!selectedOption) {
      setMandatory(true);
    } else {
      const optionSel = currentQuestion.multiple
        ? selectedOption[0]
        : selectedOption;

      if (currentQuestion.id == 0 && idSurvey == null) {
        //Se id = 0 e idSurvey non è null
        //Creo l'id survey.
        let res_id = await api.post("/survey");
        setIdSurvey(res_id.data);

        let obj = {}; //Salvo il detail
        obj.id_survey = res_id.data;
        obj.domanda = currentQuestion.id;
        obj.risposta = selectedOption.text;
        obj.contatore = counter;
        obj.tipo_azione = "Avanti";
        let res_detail = await api.post("/surveyDetail", {
          survey_detail: obj,
        });

        setCounter((prev) => prev + 1); //aggiorno il counter
      } else {
        let obj = {}; //Salvo il detail
        obj.id_survey = idSurvey;
        obj.domanda = currentQuestion.id;
        obj.risposta = optionSel.text; //valutare se mettere multiplo
        obj.contatore = counter;
        obj.tipo_azione = "Avanti";
        let res_detail = await api.post("/surveyDetail", {
          survey_detail: obj,
        });

        setCounter((prev) => prev + 1); //aggiorno il counter
      }

      if (currentQuestion.id == 4) {
        //se sto scegliendo la categoria
        setServizi(selectedOption.map((el) => el.id));
      }

      if (currentQuestion.id == 5) {
        //se sto scegliendo l'approccio
        setApproccio(selectedOption.id);
      }

      if (currentQuestion.id == 6) {
        //se sto scegliendo il sesso
        setSesso(selectedOption.text);
      }

      if (currentQuestion.id == 7) {
        // se sto scegliendo il periodo
        await insertIntoSurveyPromo();
        setPeriodi(selectedOption.map((el) => el.id));
      }

      if (optionSel.path) {
        setPath(optionSel.path);
      }
      setMandatory(false);
      setAnswers([
        ...answers,
        {
          step,
          response: selectedOption,
          question: currentQuestion.question,
          multiple: currentQuestion.multiple,
        },
      ]);

      if (optionSel.continue) {
        setStep(optionSel.nextStep);
      } else {
        setCompleted(true);
      }
      document.getElementById("MyDiv").scrollIntoView();
      setSelectedOption(null);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    const result = await api.post("/SB/JSONRP", {
      url: "https://user-api.simplybook.me/",
      method: "getCategoriesList",
      params: null,
    });
    let ids = result.data[5].events.filter((el) => el != 15); //prendo solo ciò che mi serve e tolgo terapia online

    const res_services = await api.post("/SB/REST", {
      url: "/admin/services",
      method: "get",
    });

    let selected_services = res_services.data.data
      .filter((el) => ids.indexOf(el.id.toString()) !== -1)
      .map((el) => {
        return { text: el.name, nextStep: 5, continue: true, id: el.id };
      });
    let q = [...quiz];
    q[5].options = selected_services; //QUELLO CHE SERVE A ME  OVVERO LE CATEGORIE
    setLoading(false);
  };

  //#endregion

  //#region Back 15

  const handleBack = async () => {
    if (step > 0) {
      const previousAnswer = answers.pop();
      setMandatory(false);
      setAnswers([...answers]);
      setStep(previousAnswer.step);
      setSelectedOption(null);
      setCompleted(false);

      let obj = {}; //Salvo il detail
      obj.id_survey = idSurvey;
      obj.domanda = currentQuestion.id;
      obj.risposta = null; //valutare se mettere multiplo
      obj.contatore = counter;
      obj.tipo_azione = "Indietro";
      let res_detail = await api.post("/surveyDetail", {
        survey_detail: obj,
      });

      setCounter((prev) => prev + 1); //aggiorno il conter
    }
  };
  //#endregion

  const currentQuestion = quiz.filter((el) => el.id == step)[0];
  return (
    <>
      {/* <Header  />*/}
      <div className="h-[calc(100vh_-_84px)] survey-mamachat mx-1 mb-1 bg-[#ffffff] pt-2 pb-2">
        {/*<div className="w-full h-24 p-4 flex justify-center invisible"></div> */}
        <div className="h-full bg-[#fdf5ea] bg-center bg-no-repeat w-full max-w-lg mx-auto flex flex-col rounded-md m-2 overflow-hidden mb-2">
          {completed && (
            <div className="flex flex-col h-full ">{renderFinalStep()}</div>
          )}

          {!completed && (
            <div className="flex flex-col h-full">
              <div className="p-5 z-10 bg-[#AC005A] rounded-b-xl">
                <QuestionCard
                  text={currentQuestion.question}
                  step={step}
                  answers={answers}
                />
              </div>
              {(currentQuestion.id == 4 ||
                currentQuestion.id == 5 ||
                currentQuestion.id == 7) &&
                usePromo === 1 && (
                  <div
                    className={`bg-mamaottanio text-center text-2xl text-mamagiallo font-bold rounded-md p-3 ml-3 mr-3 mt-2 relative duration-500 cursor-pointer border-2`}
                  >
                    <h4>la prima visita è gratuita!</h4>
                  </div>
                )}

              <div className="flex-1 overflow-auto p-5 no-scrollbar ">
                <div
                  className={`text-red-500 text-lg italic transition-opacity ${
                    mandatory ? "" : "hidden"
                  }`}
                >
                  *Selezionare almeno una risposta!
                </div>

                <div id="MyDiv" className="space-y-5 overflow-hidden p-2">
                  {currentQuestion.multiple
                    ? currentQuestion.options.map((option, index) => (
                        <div
                          className={`bg-white rounded-md p-3 relative duration-500 cursor-pointer border-2 ${
                            mandatory && " border-red-500"
                          } ${
                            selectedOption &&
                            selectedOption
                              ?.map((el) => el.text)
                              .indexOf(option.text) !== -1 &&
                            " border-mamachat-600"
                          }`}
                          key={index}
                          onClick={() => {
                            setMandatory(false);
                            let s = selectedOption ? [...selectedOption] : [];
                            let indexEl = s
                              ?.map((el) => el.text)
                              .indexOf(option.text);
                            if (indexEl === -1) {
                              s.push(option);
                            } else {
                              s.splice(indexEl, 1);
                            }
                            setSelectedOption(s);
                          }}
                        >
                          {selectedOption &&
                            selectedOption
                              ?.map((el) => el.text)
                              .indexOf(option.text) !== -1 && (
                              <div className="absolute -top-2 -right-2 rounded-md bg-mamachat-600 border-mamachat-600">
                                <MdCheck className="text-white text-lg" />
                              </div>
                            )}
                          {option.text}
                        </div>
                      ))
                    : currentQuestion.options.map((option, index) => (
                        <div
                          className={`bg-white rounded-md p-3 relative duration-500 cursor-pointer border-2 ${
                            mandatory && " border-red-500"
                          } ${
                            selectedOption?.text === option.text &&
                            " border-mamachat-600"
                          }`}
                          key={index}
                          onClick={() => {
                            setMandatory(false);
                            let a = [...answers];
                            a[step] = { text: option.text, index: index };
                            setSelectedOption(option);
                          }}
                        >
                          {selectedOption?.text === option.text && (
                            <div className="absolute -top-2 -right-2 rounded-md bg-mamachat-600 border-mamachat-600">
                              <MdCheck className="text-white text-lg" />
                            </div>
                          )}
                          {option.text}
                        </div>
                      ))}
                </div>

                {result !== "" && (
                  <div className="text-center text-white text-3xl font-bold">
                    {result}
                  </div>
                )}
              </div>
              <div className="p-5 shadow-md z-10 flex items-center justify-end gap-x-2 bg-mamaottanio rounded-t-xl">
                <button
                  disabled={step === 0}
                  onClick={handleBack}
                  className="text-mamagiallo justify-center inline-flex items-center focus:ring-2 focus:outline-none focus:ring-[#58c7cf] font-bold rounded-md text-sm px-5 py-2.5"
                >
                  <FaArrowLeftLong className="mr-3" />
                  Indietro
                </button>
                <button
                  onClick={() =>
                    result === "" ? handleNext() : console.log(answers, step)
                  }
                  className="buttonGialloChat"
                >
                  {result === "" ? "Avanti" : "Fine"}
                  {result === "" ? (
                    <FaArrowRightLong className="ml-3" />
                  ) : (
                    <FaCheck className="ml-3" />
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const RenderSpecialistaPsicologia = ({
  answers,
  tipologia,
  servizi,
  approccio,
  sesso,
  periodi,
  handleBack,
  idSurvey,
  counter,
  currentQuestionId,
  usePromo,
}) => {
  const [specialisti, setSpecialisti] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providerSel, setProviderSel] = useState(false);
  const [slotsProvider, setSlotsProviders] = useState([]);
  const [slotsForDayProvider, setSlotsForDayProvider] = useState([]);
  const [selectedDate, setSelectedDate] = useState(false);
  const [orari, setOrari] = useState([]);
  const [orarioSel, setOrarioSel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [elem, setElem] = useState(false);
  const [helpMe, setHelpMe] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (orarioSel) {
      setOpenModal(true);
    }
  }, [orarioSel]);

  const selectProvider = (element) => {
    setOrari([]);
    setOrarioSel(false);
    setOpenModal(false);
    setSlotsForDayProvider(
      slotsProvider.filter((el) => el.id == element.id)[0].slots
    );
    setProviderSel(element);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const res_date = await api.post("/SB/REST", {
        url: `/admin/providers?filter[service_id]=${tipologia}`,
        method: "get",
      });
      let data = res_date.data.data;

      if (approccio !== 12) {
        let providers_approccio = providers
          .filter((el) => el.approccio == approccio)
          .map((el) => el.id); //filtro dall'elenco degli approcci
        data = data.filter((el) => providers_approccio.indexOf(el.id) !== -1); //filtro tutti i provider con quell'approccio con l'id di simplybook
      }
      data = data.filter((el) =>
        servizi.every((elem) => el.services.includes(elem.toString()))
      ); //filtro tutti i provider con quei servizi

      /* if (sesso == "Uomo") {  //filtro uomo
        data = data.filter(el => !el.name.includes("Dott.ssa"))
      }
      if (sesso == "Donna") { //filtro donna
        data = data.filter(el => el.name.includes("Dott.ssa"))
      } */

      const res_slots = await api.post("/checkSlot", {
        data: data.map((el) => el.id),
        servizio: servizi[0],
        da: moment(new Date()).format("YYYY-MM-DD"),
        a: moment(new Date()).add(14, "days").format("YYYY-MM-DD"),
        periodi: periodi.join(),
      });

      let avaiables = res_slots.data.providers;
      let slots = res_slots.data.disponibilta;
      setSlotsProviders(slots);
      setSpecialisti(data.filter((el) => avaiables.indexOf(el.id) !== -1));
      setLoading(false);
      setProviderSel(false);
    } catch (e) {
      alert("Errore durante il caricamento");
    }
  };

  const handleInfoClick = (elem) => {
    setElem(elem);
    setInfoModal(true);
  };
  const loadAppointments = (date) => {
    setOrari(
      slotsForDayProvider.filter(
        (el) => el.date == date.format("YYYY-MM-DD")
      )[0].slots
    );
  };

  const handleConfirm = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "conferma_click",
      test_variable: "Conferma", // Nome del tuo pulsante
    });

    let obj = {}; //Salvo il detail
    obj.id_survey = idSurvey;
    obj.domanda = currentQuestionId;
    obj.risposta = null; //valutare se mettere multiplo
    obj.contatore = counter;
    obj.tipo_azione = "Avanti";
    obj.data = moment(new Date(selectedDate ? selectedDate : "")).format(
      "YYYY-MM-DD"
    );
    obj.orario = orarioSel;
    obj.specialista = providerSel.name;
    try {
      let res_detail = await api.post("/surveyDetail", {
        survey_detail: obj,
      });
    } catch (e) {
      alert("Errore in fase di salvataggio survey");
    }
    navigate("/booking", {
      state: {
        time: orarioSel,
        date: moment(new Date(selectedDate ? selectedDate : "")).format(
          "YYYY-MM-DD"
        ),
        usePromo: usePromo,
        idSurvey: idSurvey,
        provider: providerSel,
        servizio: servizi[0],
      },
    });
  };

  return (
    <>
      {infoModal &&
        createPortal(
          <div className="fixed inset-0 z-[120] w-full h-[full] bg-gray-600 bg-opacity-50">
            <div
              className={`transform  relative w-full min-h-[calc(100%-1rem)] mx-auto my-auto flex items-center justify-center `}
            >
              <div
                className={`transform relative bg-white rounded-md shadow transition-opacity duration-400 max-w-sm md:max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl min-w-sm md:min-w-screen-md lg:min-w-screen-lg 2xl:min-w-screen-xl my-auto border border-slate-200
									w-1/2 p-4`}
              >
                <div className="flex items-center justify-center p-4  relative flex-wrap flex-col">
                  <div>
                    <h3 className="text-lg md:text-2xl font-medium text-gray-900 capitalize">
                      {elem?.name}
                    </h3>
                  </div>
                  <div>
                    <img
                      className="w-24 h-24 rounded-full object-cover"
                      src={
                        "https://mamamind.secure.simplybook.it/" +
                        elem?.picture_preview
                      }
                      alt="image"
                    />
                  </div>
                  <div
                    type="button"
                    className="absolute -right-4 -top-4 text-gray-400 bg-white  rounded-full text-sm p-1.5 ml-auto inline-flex items-center cursor-pointer transition-all duration-200 hover:scale-110 hover:bg-gray-300 hover:text-white"
                    data-modal-hide="staticModal"
                    onClick={() => setInfoModal(false)}
                  >
                    <FaX className="button-icon" />
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: elem?.description }}
                  className="h-[40vh] overflow-y-auto"
                />
              </div>
            </div>
          </div>,

          document.getElementById("modal")
        )}
      <div className="p-5 z-10 bg-[#AC005A] rounded-b-xl">
        <QuestionCard text={"Seleziona lo specialista"} answers={answers} />
      </div>
      {usePromo == 1 && (
        <div
          className={`bg-mamaottanio text-center text-2xl text-mamagiallo font-bold rounded-md p-3 ml-3 mr-3 mt-2 relative duration-500 cursor-pointer border-2`}
        >
          <h4>la prima visita è gratuita!</h4>
        </div>
      )}
      {loading && (
        <center>
          <div className="mt-10 p-2 text-mamarosso">
            {" "}
            Attendi ancora un momento, stiamo selezionando con cura i profili
            più in linea con le tue necessità.
          </div>
          <div>
            <CircularProgress
              className="text-mamaottanio"
              sx={{ color: "#1a757d" }}
            />
          </div>
          <div className="mt-2 p-2 text-mamarosso">
            Se hai domande o hai bisogno di assistenza, non esitare a
            contattarci all'indirizzo&nbsp;
            <span className="underline text-mamaottanio ml-2">
              <a href="videoterapie@mamamind.it">videoterapie@mamamind.it</a>
            </span>
          </div>
        </center>
      )}

      <div className="flex-1 overflow-auto p-5 no-scrollbar flex-1">
        {!loading &&
          (specialisti.length > 0 && !helpMe ? (
            specialisti.map((element, index) => (
              <>
                <div
                  style={{ marginTop: "5px" }}
                  className={
                    element.name == providerSel?.name
                      ? "cardProvSel"
                      : "cardProv"
                  }
                  onClick={() => selectProvider(element)}
                >
                  <div className="flex-shrink-0">
                    <img
                      className="w-24 h-24 rounded-full object-cover"
                      src={
                        "https://mamamind.secure.simplybook.it/" +
                        element.picture_preview
                      }
                      alt="image"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-md font-medium  truncate ">
                      {element.name}
                    </p>
                  </div>
                  <div className="flex bg-white rounded-full">
                    <FaInfoCircle
                      data-tip
                      data-for="infoTooltip"
                      className="text-mamarosso cursor-pointer hover:text-mamarosa"
                      size={30}
                      onClick={() => handleInfoClick(element)}
                    />
                  </div>
                </div>
                <div
                  className={
                    element.name == providerSel?.name
                      ? "bg-white w-full rounded-md mt-2"
                      : "hidden"
                  }
                >
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="it"
                  >
                    <DateCalendar
                      disablePast
                      onChange={(el) => {
                        setSelectedDate(new Date(el));
                        loadAppointments(el);
                      }}
                      //onMonthChange={(el) => loadMonthAvaiability(el.startOf('month').format('YYYY-MM-DD'), el.endOf('month').format('YYYY-MM-DD'))}
                      shouldDisableDate={(d) => {
                        return (
                          slotsForDayProvider
                            .map((el) => el.date)
                            .indexOf(moment(d).format("YYYY-MM-DD")) === -1
                        );
                      }}
                      //loading={loadingCalendar}
                      renderLoading={() => (
                        <CircularProgress sx={{ color: "#008C95" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div
                  className={
                    orari.length > 0 && element.name == providerSel?.name
                      ? "w-full rounded-md flex flex-row flex-wrap"
                      : "hidden"
                  }
                >
                  {orari.map((el) => (
                    <div key={el.id} className="mb-2 mt-2">
                      <button
                        key={el.id}
                        className="buttonOttanioChat mr-2"
                        onClick={() => setOrarioSel(el.time)}
                      >
                        {el.time.slice(0, -3)}
                      </button>
                    </div>
                  ))}
                </div>
              </>
            ))
          ) : (
            <RenderContattaci
              answers={answers}
              helpMe={helpMe}
              idSurvey={idSurvey}
            />
          ))}
      </div>
      <div className="p-5 shadow-md z-10 flex items-center justify-end gap-x-2 bg-mamaottanio rounded-t-xl">
        {!helpMe && (
          <button
            onClick={handleBack}
            className="text-mamagiallo justify-center inline-flex items-center focus:ring-2 focus:outline-none focus:ring-[#58c7cf] font-bold rounded-md text-sm px-5 py-2.5"
          >
            <FaArrowLeftLong className="mr-3" />
            Indietro
          </button>
        )}
        {openModal ? (
          <button onClick={() => handleConfirm()} className="buttonGialloChat">
            Conferma
            <FaCheck className="ml-3" />
          </button>
        ) : helpMe ? (
          <button
            onClick={() => setHelpMe(false)}
            className="text-mamagiallo justify-center inline-flex items-center focus:ring-2 focus:outline-none focus:ring-[#58c7cf] font-bold rounded-md text-sm px-5 py-2.5"
          >
            <FaArrowLeftLong className="mr-3" />
            Indietro
          </button>
        ) : (
          specialisti.length > 0 &&
          usePromo != 1 && (
            <button
              onClick={() => setHelpMe(true)}
              className="buttonGialloChat"
            >
              Aiutami a decidere
              <FaCheck className="ml-3" />
            </button>
          )
        )}
      </div>
    </>
  );
};

const RenderSupportoFiglia = ({ answers, handleBack, idSurvey, counter }) => {
  const [mail, setMail] = useState("");
  const [eta, setEta] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [mailSent, setMailSent] = useState(false);

  const sendMail = async () => {
    try {
      if (descrizione.length == 0) {
        alert("Per proseguire devi inserire una descrizione");
        return;
      }
      if (!validator.isEmail(mail)) {
        alert("Per proseguire devi inserire una mail valida");
        return;
      }
      if (eta >= 18) {
        alert("l'età del minore deve essere inferiore ai 18 anni");
        return;
      }
      const res_mail = api.post("/sendmail", {
        eta: eta,
        mail_genitore: mail,
        motivo: descrizione,
        oggetto: "richiesta da parte di un genitore",
      });
      const res_update = await api.put("/survey", {
        survey: { operation: "email", id: idSurvey, email_address: mail },
      });
      setMailSent(true);
    } catch {
      alert("Errore durante l'invio della mail");
    }
  };

  return (
    <>
      <div className="p-5 z-10 bg-[#AC005A] rounded-b-xl">
        <QuestionCard
          text={
            "Compila il form, Ti contatteremo per fornirti maggiori informazioni"
          }
          answers={answers}
        />
      </div>

      {mailSent && (
        <div className="w-full grid grid-cols-2 gap-2 content-center flex-1 justify-items-center">
          <div className="col-span-2 text-mamarosso">
            Grazie per aver compilato il form, il team di Mama Mind vi
            contatterà il prima possibile
          </div>
        </div>
      )}
      {!mailSent && (
        <div className="w-full grid grid-cols-2 gap-2 content-center flex-1">
          <div className="col-span-2 p-2">
            <div className="mb-5 ">
              <label
                htmlFor="fName"
                className="mb-3  text-base font-medium text-[#07074D]"
              >
                Quanti anni ha la/il minore?
              </label>
              <input
                type="text"
                value={eta}
                onChange={(e) => setEta(e.target.value)}
                placeholder="Età"
                className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
              />
            </div>
          </div>
          <div className="col-span-2 p-2">
            <div className="mb-5">
              <label
                htmlFor="fName"
                className="mb-3  text-base font-medium text-[#07074D]"
              >
                Lascia qui la tua mail
              </label>
              <input
                type="text"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                placeholder="Mail"
                className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
              />
            </div>
          </div>

          <div className="col-span-2 p-2">
            <div className="mb-5">
              <label
                htmlFor="fName"
                className="mb-3  text-base font-medium text-[#07074D]"
              >
                Descrivi la situazione e il motivo del contatto
              </label>
              <textarea
                type="area"
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
                placeholder="Motivo"
                className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
              />
            </div>
          </div>
          <div className="col-span-2 justify-self-center	">
            <button
              onClick={sendMail}
              title=""
              className="text-white font-bold bg-[#008C95] justify-center inline-flex items-center  focus:ring-2 focus:outline-none focus:ring-[#58c7cf] rounded-md text-sm px-5 py-2.5 text-center "
              role="button"
            >
              Conferma
              <FaCheck className="ml-3" />
            </button>
          </div>
        </div>
      )}
      {!mailSent && (
        <div className="p-5 shadow-md z-10 flex items-center justify-end gap-x-2 bg-mamaottanio rounded-t-xl">
          <button
            onClick={handleBack}
            className="text-mamagiallo justify-center inline-flex items-center focus:ring-2 focus:outline-none focus:ring-[#58c7cf] font-bold rounded-md text-sm px-5 py-2.5"
          >
            <FaArrowLeftLong className="mr-3" />
            Indietro
          </button>
        </div>
      )}
    </>
  );
};

const RenderMinoreNoGen = ({ answers, handleBack, counter }) => {
  //qua come finire il giro?

  return (
    <>
      <div className="p-5 z-10 bg-[#AC005A] rounded-b-xl">
        <QuestionCard
          text={
            "Ti invitiamo a contattare l'Associazione Mama Chat per ricevere una prima accoglienza e indirizzamento gratuito"
          }
          answers={answers}
        />
      </div>
      <div className="w-full grid grid-cols-2 gap-2 content-center flex-1 items-center justify-items-center">
        <div className="col-span-2">
          <button
            onClick={() =>
              (window.location.href = "https://mamachat.org/chat/")
            }
            title=""
            className="buttonOttanioChat "
            role="button"
          >
            Entra in chat
            <FaMessage className="ml-3" />
          </button>
        </div>
      </div>
      <div className="p-5 shadow-md z-10 flex items-center justify-end gap-x-2 bg-mamaottanio rounded-t-xl">
        <button
          onClick={handleBack}
          className="text-mamagiallo justify-center inline-flex items-center focus:ring-2 focus:outline-none focus:ring-[#58c7cf] font-bold rounded-md text-sm px-5 py-2.5"
        >
          <FaArrowLeftLong className="mr-3" />
          Indietro
        </button>
      </div>
    </>
  );
};

const RenderMinoreSiGen = ({ answers, handleBack, idSurvey, counter }) => {
  const [mail, setMail] = useState("");
  const [eta, setEta] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [mailSent, setMailSent] = useState(false);

  const sendMail = async () => {
    try {
      if (!validator.isEmail(mail)) {
        alert("Per proseguire devi inserire una mail valida");
        return;
      }
      if (descrizione.length == 0) {
        alert("Per proseguire devi inserire una descrizione");
        return;
      }
      if (eta >= 18) {
        alert("l'età del minore deve essere inferiore ai 18 anni");
        return;
      }
      const res_mail = await api.post("/sendmail", {
        eta: eta,
        mail_genitore: mail,
        motivo: descrizione,
        oggetto: "richiesta da parte di un minore",
      });

      const res_update = await api.put("/survey", {
        survey: { operation: "email", id: idSurvey, email_address: mail },
      });

      setMailSent(true);
    } catch {
      alert("Errore durante l'invio della mail");
    }
  };

  return (
    <>
      <div className="p-5 z-10 bg-[#AC005A] rounded-b-xl">
        <QuestionCard
          text={
            "Compila il form, contatteremo i tuoi genitori per fornirvi maggiori informazioni su come iniziare il percorso "
          }
          answers={answers}
        />
      </div>
      {mailSent && (
        <div className="w-full grid grid-cols-2 gap-2 content-center flex-1 justify-items-center">
          <div className="col-span-2 text-mamarosso">
            Grazie per aver compilato il form, il team di Mama Mind vi
            contatterà il prima possibile
          </div>
        </div>
      )}
      {!mailSent && (
        <div className="w-full grid grid-cols-2 gap-2 content-center flex-1">
          <div className="col-span-2 p-2">
            <div className="mb-5 ">
              <label
                htmlFor="fName"
                className="mb-3  text-base font-medium text-[#07074D]"
              >
                Quanti anni hai?
              </label>
              <input
                type="text"
                value={eta}
                onChange={(e) => setEta(e.target.value)}
                placeholder="Età"
                className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
              />
            </div>
          </div>
          <div className="col-span-2 p-2">
            <div className="mb-5">
              <label
                htmlFor="fName"
                className="mb-3  text-base font-medium text-[#07074D]"
              >
                Lascia qui la mail dei tuoi genitori
              </label>
              <input
                type="text"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                placeholder="Mail"
                className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
              />
            </div>
          </div>

          <div className="col-span-2 p-2">
            <div className="mb-5">
              <label
                htmlFor="fName"
                className="mb-3  text-base font-medium text-[#07074D]"
              >
                Descrivi la situazione e il motivo del contatto
              </label>
              <textarea
                type="area"
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
                placeholder="Motivo"
                className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
              />
            </div>
          </div>
          <div className="col-span-2 justify-self-center	">
            <button
              onClick={sendMail}
              title=""
              className="text-white font-bold bg-[#008C95] justify-center inline-flex items-center  focus:ring-2 focus:outline-none focus:ring-[#58c7cf] rounded-md text-sm px-5 py-2.5 text-center "
              role="button"
            >
              Conferma
              <FaCheck className="ml-3" />
            </button>
          </div>
        </div>
      )}
      {!mailSent && (
        <div className="p-5 shadow-md z-10 flex items-center justify-end gap-x-2 bg-mamaottanio rounded-t-xl">
          <button
            onClick={handleBack}
            className="text-mamagiallo justify-center inline-flex items-center focus:ring-2 focus:outline-none focus:ring-[#58c7cf] font-bold rounded-md text-sm px-5 py-2.5"
          >
            <FaArrowLeftLong className="mr-3" />
            Indietro
          </button>
        </div>
      )}
    </>
  );
};

const RenderSpecialista = ({
  answers,
  tipologia,
  handleBack,
  servizi,
  periodi,
  idSurvey,
  counter,
  currentQuestionId,
}) => {
  const [specialisti, setSpecialisti] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providerSel, setProviderSel] = useState(false);
  const [slotsProvider, setSlotsProviders] = useState([]);
  const [slotsForDayProvider, setSlotsForDayProvider] = useState([]);
  const [selectedDate, setSelectedDate] = useState(false);
  const [orari, setOrari] = useState([]);
  const [orarioSel, setOrarioSel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [elem, setElem] = useState(false);
  const [helpMe, setHelpMe] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (orarioSel) {
      setOpenModal(true);
    }
  }, [orarioSel]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res_date = await api.post("/SB/REST", {
        url: `/admin/providers?filter[service_id]=${tipologia}`,
        method: "get",
      });
      let data = res_date.data.data;

      /* if (sesso == "Uomo") {  //filtro uomo
        data = data.filter(el => !el.name.includes("Dott.ssa"))
      }
      if (sesso == "Donna") { //filtro donna
        data = data.filter(el => el.name.includes("Dott.ssa"))
      } */

      let prova_periodi = ["8-13", "13-18", "18-22"];

      const res_slots = await api.post("/checkSlot", {
        data: data.map((el) => el.id),
        servizio: [tipologia],
        da: moment(new Date()).format("YYYY-MM-DD"),
        a: moment(new Date()).add(14, "days").format("YYYY-MM-DD"),
        periodi: prova_periodi.join(),
      });

      let avaiables = res_slots.data.providers;
      let slots = res_slots.data.disponibilta;
      setSlotsProviders(slots);
      setSpecialisti(data.filter((el) => avaiables.indexOf(el.id) !== -1));
      setLoading(false);
      setProviderSel(false);
    } catch (e) {
      console.log(e);
      alert("Errore durante il caricamento");
    }
  };

  const selectProvider = (element) => {
    setOrari([]);
    setOrarioSel(false);
    setOpenModal(false);
    setSlotsForDayProvider(
      slotsProvider.filter((el) => el.id == element.id)[0].slots
    );
    setProviderSel(element);
  };

  const handleInfoClick = (elem) => {
    setElem(elem);
    setInfoModal(true);
  };
  const loadAppointments = (date) => {
    setOrari(
      slotsForDayProvider.filter(
        (el) => el.date == date.format("YYYY-MM-DD")
      )[0].slots
    );
  };

  const handleConfirm = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "button_click",
      button_name: "Conferma", // Nome del tuo pulsante
    });
    let obj = {}; //Salvo il detail
    obj.id_survey = idSurvey;
    obj.domanda = currentQuestionId;
    obj.risposta = null; //valutare se mettere multiplo
    obj.contatore = counter;
    obj.tipo_azione = "Avanti";
    obj.data = moment(new Date(selectedDate ? selectedDate : "")).format(
      "YYYY-MM-DD"
    );
    obj.orario = orarioSel;
    obj.specialista = providerSel.name;
    try {
      let res_detail = await api.post("/surveyDetail", {
        survey_detail: obj,
      });
    } catch (e) {
      alert("Errore in fase di salvataggio survey");
    }
    navigate("/booking", {
      state: {
        time: orarioSel,
        date: moment(new Date(selectedDate ? selectedDate : "")).format(
          "YYYY-MM-DD"
        ),
        idSurvey: idSurvey,
        provider: providerSel,
        servizio: tipologia,
      },
    });
  };

  return (
    <>
      {infoModal &&
        createPortal(
          <div className="fixed inset-0 z-[120] w-full h-[full] bg-gray-600 bg-opacity-50">
            <div
              className={`transform  relative w-full min-h-[calc(100%-1rem)] mx-auto my-auto flex items-center justify-center `}
            >
              <div
                className={`transform relative bg-white rounded-md shadow transition-opacity duration-400 max-w-sm md:max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl min-w-sm md:min-w-screen-md lg:min-w-screen-lg 2xl:min-w-screen-xl my-auto border border-slate-200
									w-1/2 p-4`}
              >
                <div className="flex items-center justify-center p-4  relative flex-wrap flex-col">
                  <div>
                    <h3 className="text-lg md:text-2xl font-medium text-gray-900 capitalize">
                      {elem?.name}
                    </h3>
                  </div>
                  <div>
                    <img
                      className="w-24 h-24 rounded-full object-cover"
                      src={
                        "https://mamamind.secure.simplybook.it/" +
                        elem?.picture_preview
                      }
                      alt="image"
                    />
                  </div>
                  <div
                    type="button"
                    className="absolute -right-4 -top-4 text-gray-400 bg-white  rounded-full text-sm p-1.5 ml-auto inline-flex items-center cursor-pointer transition-all duration-200 hover:scale-110 hover:bg-gray-300 hover:text-white"
                    data-modal-hide="staticModal"
                    onClick={() => setInfoModal(false)}
                  >
                    <FaX className="button-icon" />
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: elem?.description }}
                  className="h-[40vh] overflow-y-auto"
                />
              </div>
            </div>
          </div>,

          document.getElementById("modal")
        )}
      <div className="p-5 z-10 bg-[#AC005A] rounded-b-xl">
        <QuestionCard text={"Seleziona lo specialista"} answers={answers} />
      </div>
      {loading && (
        <center>
          <div className="mt-10 p-2 text-mamarosso">
            {" "}
            Attendi ancora un momento, stiamo selezionando con cura i profili
            più in linea con le tue necessità.
          </div>
          <div>
            <CircularProgress
              className="text-mamaottanio"
              sx={{ color: "#1a757d" }}
            />
          </div>
          <div className="mt-2 p-2 text-mamarosso">
            Se hai domande o hai bisogno di assistenza, non esitare a
            contattarci all'indirizzo
            <span className="underline text-mamaottanio">
              <a href="videoterapie@mamamind.it">videoterapie@mamamind.it</a>
            </span>
          </div>
        </center>
      )}
      <div className="flex-1 overflow-auto p-5 no-scrollbar flex-1">
        {!loading &&
          (specialisti.length > 0 && !helpMe ? (
            specialisti.map((element, index) => (
              <>
                <div
                  style={{ marginTop: "5px" }}
                  className={
                    element.name == providerSel?.name
                      ? "cardProvSel"
                      : "cardProv"
                  }
                  onClick={() => selectProvider(element)}
                >
                  <div className="flex-shrink-0">
                    <img
                      className="w-24 h-24 rounded-full object-cover"
                      src={
                        "https://mamamind.secure.simplybook.it/" +
                        element.picture_preview
                      }
                      alt="image"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-md font-medium  truncate ">
                      {element.name}
                    </p>
                  </div>
                  <div className="flex bg-white rounded-full">
                    <FaInfoCircle
                      data-tip
                      data-for="infoTooltip"
                      className="text-mamarosso cursor-pointer hover:text-mamarosa"
                      size={30}
                      onClick={() => handleInfoClick(element)}
                    />
                  </div>
                </div>
                <div
                  className={
                    element.name == providerSel?.name
                      ? "bg-white w-full rounded-md mt-2"
                      : "hidden"
                  }
                >
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="it"
                  >
                    <DateCalendar
                      disablePast
                      onChange={(el) => {
                        setSelectedDate(new Date(el));
                        loadAppointments(el);
                      }}
                      //onMonthChange={(el) => loadMonthAvaiability(el.startOf('month').format('YYYY-MM-DD'), el.endOf('month').format('YYYY-MM-DD'))}
                      shouldDisableDate={(d) => {
                        return (
                          slotsForDayProvider
                            .map((el) => el.date)
                            .indexOf(moment(d).format("YYYY-MM-DD")) === -1
                        );
                      }}
                      //loading={loadingCalendar}
                      renderLoading={() => (
                        <CircularProgress sx={{ color: "#008C95" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div
                  className={
                    orari.length > 0 && element.name == providerSel?.name
                      ? "w-full rounded-md flex flex-row flex-wrap"
                      : "hidden"
                  }
                >
                  {orari.map((el) => (
                    <div key={el.id} className="mb-2 mt-2">
                      <button
                        key={el.id}
                        className="buttonOttanioChat mr-2"
                        onClick={() => setOrarioSel(el.time)}
                      >
                        {el.time.slice(0, -3)}
                      </button>
                    </div>
                  ))}
                </div>
              </>
            ))
          ) : (
            <RenderContattaci
              helpMe={helpMe}
              answers={answers}
              idSurvey={idSurvey}
            />
          ))}
      </div>
      <div className="p-5 shadow-md z-10 flex items-center justify-end gap-x-2 bg-mamaottanio rounded-t-xl">
        {!helpMe && (
          <button
            onClick={handleBack}
            className="text-mamagiallo justify-center inline-flex items-center focus:ring-2 focus:outline-none focus:ring-[#58c7cf] font-bold rounded-md text-sm px-5 py-2.5"
          >
            <FaArrowLeftLong className="mr-3" />
            Indietro
          </button>
        )}
        {openModal ? (
          <button onClick={() => handleConfirm()} className="buttonGialloChat">
            Conferma
            <FaCheck className="ml-3" />
          </button>
        ) : helpMe ? (
          <button
            onClick={() => setHelpMe(false)}
            className="text-mamagiallo justify-center inline-flex items-center focus:ring-2 focus:outline-none focus:ring-[#58c7cf] font-bold rounded-md text-sm px-5 py-2.5"
          >
            <FaArrowLeftLong className="mr-3" />
            Indietro
          </button>
        ) : (
          specialisti.length > 0 && (
            <button
              onClick={() => setHelpMe(true)}
              className="buttonGialloChat"
            >
              Aiutami a decidere
              <FaCheck className="ml-3" />
            </button>
          )
        )}
      </div>
    </>
  );
};

const arraysHaveSameElements = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  let sortedArr1 = [...arr1].sort();
  let sortedArr2 = [...arr2].sort();
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
};

const RenderContattaci = ({ answers, handleBack, helpMe, idSurvey }) => {
  const [mail, setMail] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [checkTermini, setCheckTermini] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [termini, setTermini] = useState(false);
  const sendMail = async () => {
    try {
      if (!termini) {
        setCheckTermini(true);
        alert("Per proseguire devi accettare i termini e le condizioni");
        return;
      } else {
        setCheckTermini(false);
      }
      if (descrizione.length == 0) {
        alert("Per proseguire devi inserire una descrizione");
        return;
      }
      if (!validator.isEmail(mail)) {
        alert("Per proseguire devi inserire una mail valida");
        return;
      }
      const res_mail = api.post("/sendmailhelp", {
        mail: mail,
        descrizione: descrizione,
        risposte: answers,
        privacy: privacy,
        termini: termini,
      });
      const res_update = await api.put("/survey", {
        survey: { operation: "email", id: idSurvey, email_address: mail },
      });
      setMailSent(true);
      console.log(idSurvey);
    } catch {
      alert("Errore durante l'invio della mail");
    }
  };

  return (
    <>
      {mailSent && (
        <div className="w-full grid grid-cols-2 gap-2 content-center flex-1 justify-items-center">
          <div className="col-span-2 text-mamarosso">
            Grazie per aver compilato il form, il team di Mama Mind vi
            contatterà il prima possibile
          </div>
        </div>
      )}
      {!mailSent && (
        <div className="w-full grid grid-cols-2 gap-2 content-center flex-1">
          {!helpMe && (
            <div className="col-span-2 p-2 text-mamarosso">
              Purtroppo non sono presenti professionisti disponibili con le
              selezioni da te effettuate, scrivi di seguito cosa stai cercando e
              ti aiuteremo a trovare il/la professionista indicato/a.
            </div>
          )}
          <div className="col-span-2 p-2">
            <div className="mb-5">
              <label
                htmlFor="fName"
                className="mb-3  text-base font-medium text-[#07074D]"
              >
                Inserisci la tua mail
              </label>
              <input
                type="text"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                placeholder="Mail"
                className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
              />
            </div>
          </div>

          <div className="col-span-2 p-2">
            <div className="mb-5">
              <label
                htmlFor="fName"
                className="mb-3  text-base font-medium text-[#07074D]"
              >
                Descrivi il più possibile la tua situazione
              </label>
              <textarea
                type="area"
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
                placeholder="Motivo"
                className="w-full rounded-md border border-[#e7bed0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#CFABBB] focus:shadow-md"
              />
            </div>
          </div>
          <div className="col-span-2 justify-self-left mb-5">
            <div>
              <label className="inline-flex items-center mt-3">
                <input
                  type="checkbox"
                  className={
                    "form-checkbox h-5 w-5 rounded-full " +
                    (checkTermini ? " text-mamarosso" : " text-mamaottanio")
                  }
                  checked={termini}
                  onClick={() => setTermini(!termini)}
                />
                <span
                  className={
                    "ml-2 " +
                    (checkTermini ? " text-mamarosso" : " text-mamaottanio")
                  }
                >
                  {" "}
                  <a href="https://mamamind.it/privacy-policy/" target="_blank">
                    <span className="underline">
                      Acconsento ad essere contattato per ricevere supporto
                      nella prenotazione.*
                    </span>{" "}
                    {checkTermini && " Campo obbligatorio"}
                  </a>
                </span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center mt-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-mamaottanio rounded-full"
                  checked={privacy}
                  onClick={() => setPrivacy(!privacy)}
                />
                <span className="ml-2 text-mamaottanio underline">
                  <a href="https://mamamind.it/privacy-policy/" target="_blank">
                    Acconsento a ricevere messaggi di carattere promozionale.
                  </a>
                </span>
              </label>
            </div>
          </div>
          <div className="col-span-2 justify-self-center	">
            <button
              onClick={sendMail}
              title=""
              className="text-white font-bold bg-[#008C95] justify-center inline-flex items-center  focus:ring-2 focus:outline-none focus:ring-[#58c7cf] rounded-md text-sm px-5 py-2.5 text-center "
              role="button"
            >
              Invia la richiesta
              <FaCheck className="ml-3" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
